import { Collection, Database, Q } from '@nozbe/watermelondb';

import InventoryChange from './InventoryChange';
import InventoryProduct from './InventoryProduct';
import ShoppingListProduct from './ShoppingListProduct';
import Organisation from './Organisation';
import { ProductDBServiceOptions } from '../../types/dbService';
import { InventoryChangeModel } from '../../types/InventoryChange';
import { InventoryProductModel } from '../../types/InventoryProduct';
import {
    ParsedShoppingListProduct,
    ShoppingListModel,
    ShoppingListPayload,
} from '../../types/ShoppingList';
import { ShoppingListProductModel } from '../../types/ShoppingListProduct';
import { getLocal, getNowISO } from '../../utils/date';
import Product from './Product';
import { ProductType } from '../../types/Products';
import { SORT_TYPE } from '../../constants/sort';

class ShoppingList {
    private database: Database;
    private collection: Collection<ShoppingListModel>;
    private table = 'shopping_lists';
    private options: ProductDBServiceOptions;

    constructor(options: ProductDBServiceOptions) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll(...queries: Q.Clause[]) {
        return this.collection.query(...queries).fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    private filterShoppingLists(searchText?: string) {
        const queries: Q.Clause[] = [];

        if (searchText) {
            const parsedSearchText = searchText.toLowerCase().trim();
            const sanitizedSearch = Q.sanitizeLikeString(parsedSearchText);
            queries.push(
                Q.or(
                    Q.where('name', Q.like(`${sanitizedSearch}%`)),
                    Q.where('name', Q.like(`% ${sanitizedSearch}%`)),
                ),
            );
        }

        return this.collection.query(...queries);
    }

    getFilteredShoppingLists(searchText?: string, sort?: SORT_TYPE) {
        const query = this.filterShoppingLists(searchText);
        return query.extend(Q.sortBy('name', sort ?? SORT_TYPE.ASC));
    }

    getFilteredShoppingListsCount(searchText: string) {
        const query = this.filterShoppingLists(searchText);
        return query.fetchCount();
    }

    getRecentlyCreatedShoppingLists(limit = 1) {
        const today = getLocal();

        const tomorrow = today.plus({ days: 1 });
        const formattedTomorrow = Q.sanitizeLikeString(
            tomorrow.toISO().split('T')[0],
        ).replace(/_/g, '-');

        return this.collection.query(
            Q.where(
                'created_at',
                Q.lt(Q.sanitizeLikeString(formattedTomorrow)),
            ),
            Q.sortBy('created_at', Q.desc),
            Q.take(limit),
        );
    }

    async addShoppingListProducts(
        shoppingList: ShoppingListModel,
        shoppingListProducts: ParsedShoppingListProduct[],
        userId: string,
    ) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const shoppingListProductsService = new ShoppingListProduct({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        let result: ShoppingListProductModel[] = [];
        if (shoppingList && shoppingListProducts.length > 0) {
            result = await shoppingListProductsService.prepareAdd(
                shoppingListProducts,
                shoppingList.id,
                userId,
                organisationID,
            );
        }

        return result;
    }

    async add(payload: ShoppingListPayload, userId: string) {
        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        return await this.database.write(async () => {
            const createdShoppingList = await this.collection.create(
                (shoppingList) => {
                    shoppingList.userId = userId;
                    shoppingList.organisationId = organisationID;
                    shoppingList.name = payload.name;
                    shoppingList.addedToInventoryTime =
                        payload.addedToInventoryTime;
                    shoppingList.sentToDealerTime = payload.sentToDealerTime;
                    shoppingList.dealerId = payload.dealerId;
                },
            );

            this.options.logDBAction({
                message: 'Create shopping list',
                modelName: this.table,
                payload: createdShoppingList,
            });

            const { shoppingListProducts } = payload;
            const shoppingListProductsToAdd =
                await this.addShoppingListProducts(
                    createdShoppingList,
                    shoppingListProducts,
                    userId,
                );

            this.database.batch(...shoppingListProductsToAdd);
            if (shoppingListProductsToAdd.length > 0) {
                this.options.logDBAction({
                    message:
                        'Create shopping list - create shopping list products',
                    modelName: this.table,
                    payload: shoppingListProductsToAdd,
                });
            }

            return createdShoppingList;
        });
    }

    async update(id: string, payload: ShoppingListPayload, userId: string) {
        const shoppingListElement = await this.getByID(id);
        const { shoppingListProducts } = payload;

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const shoppingListProductService = new ShoppingListProduct({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        let updatedShoppingListProducts: ShoppingListProductModel[] = [];

        return await this.database.write(async () => {
            await shoppingListElement.update((shoppingList) => {
                shoppingList.userId = shoppingListElement.userId;
                shoppingList.organisationId =
                    shoppingListElement.organisationId;
                shoppingList.name = payload.name;
                shoppingList.addedToInventoryTime =
                    shoppingListElement.addedToInventoryTime;
                shoppingList.sentToDealerTime =
                    shoppingListElement.sentToDealerTime;
                shoppingList.dealerId = shoppingListElement.dealerId;
            });

            this.options.logDBAction({
                message: 'Update shopping list',
                modelName: this.table,
                payload: shoppingListElement,
            });

            const result = await shoppingListProductService.prepareUpdate(
                shoppingListElement,
                shoppingListProducts,
                userId,
                organisationID,
            );

            const {
                shoppingListProductsToAdd,
                shoppingListProductsToUpdate,
                shoppingListProductsToDelete,
            } = result;

            updatedShoppingListProducts = [
                ...shoppingListProductsToAdd,
                ...shoppingListProductsToUpdate,
            ];

            this.database.batch(
                ...shoppingListProductsToAdd,
                ...shoppingListProductsToUpdate,
                ...shoppingListProductsToDelete,
            );
            this.logUpdateBatch({
                shoppingListProductsToAdd,
                shoppingListProductsToUpdate,
                shoppingListProductsToDelete,
            });

            return updatedShoppingListProducts;
        });
    }

    logUpdateBatch({
        shoppingListProductsToAdd,
        shoppingListProductsToUpdate,
        shoppingListProductsToDelete,
    }) {
        if (shoppingListProductsToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update shopping list - create shopping list products',
                modelName: this.table,
                payload: shoppingListProductsToAdd,
            });
        }

        if (shoppingListProductsToUpdate.length > 0) {
            this.options.logDBAction({
                message: 'Update shopping list - update shopping list products',
                modelName: this.table,
                payload: shoppingListProductsToUpdate,
            });
        }

        if (shoppingListProductsToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Update shopping list - delete shopping list products',
                modelName: this.table,
                payload: shoppingListProductsToDelete,
            });
        }
    }

    async addToInventory(id: string, userId: string) {
        const shoppingListElement = await this.getByID(id);
        const shoppingListProducts =
            await shoppingListElement.shoppingListProducts.fetch();

        const inventoryProductService = new InventoryProduct({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const inventoryChangesService = new InventoryChange({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const inventoryProductsCollection =
            this.database.collections.get<InventoryProductModel>(
                'inventory_products',
            );

        const organisationService = new Organisation({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const productService = new Product({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
            logError: this.options.logError,
        });

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const addedToInventoryTime = getLocal().toISO();

        let inventoryProductsToAdd: InventoryProductModel[] = [];
        let inventoryChanges: InventoryChangeModel[] = [];

        await this.database.write(async () => {
            await shoppingListElement.update((shoppingList) => {
                shoppingList.addedToInventoryTime = addedToInventoryTime;
            });

            await Promise.all(
                shoppingListProducts.map(async (shoppLstProd) => {
                    const productSize = await productService.getByID(
                        shoppLstProd.productId,
                    );

                    if (productSize.productType === ProductType.custom) {
                        return;
                    }

                    const inventoryProduct =
                        await inventoryProductService.getByParam(
                            'product_id',
                            shoppLstProd.productId,
                        );
                    if (inventoryProduct.length === 1) {
                        const inventoryChange =
                            await inventoryChangesService.prepareAdd(
                                {
                                    inventoryProductId: inventoryProduct[0].id,
                                    quantityChange: shoppLstProd.totalQuantity,
                                    processAt: new Date(getNowISO()),
                                },
                                userId,
                            );

                        inventoryChanges = [
                            ...inventoryChanges,
                            inventoryChange,
                        ];
                    } else {
                        const inventoryProductToAdd =
                            await inventoryProductsCollection.prepareCreate(
                                (inventoryProd) => {
                                    inventoryProd.productId =
                                        shoppLstProd.productId;
                                    inventoryProd.userId = userId;
                                    inventoryProd.organisationId =
                                        organisationID;
                                    inventoryProd.quantity = 0;
                                    inventoryProd.favourite = false;
                                },
                            );

                        inventoryProductsToAdd = [
                            ...inventoryProductsToAdd,
                            inventoryProductToAdd,
                        ];

                        const inventoryChange =
                            await inventoryChangesService.prepareAdd(
                                {
                                    inventoryProductId:
                                        inventoryProductToAdd.id,
                                    quantityChange: shoppLstProd.totalQuantity,
                                    processAt: new Date(getNowISO()),
                                },
                                userId,
                            );

                        inventoryChanges = [
                            ...inventoryChanges,
                            inventoryChange,
                        ];
                    }
                }),
            );

            this.database.batch(...inventoryProductsToAdd, ...inventoryChanges);
            this.logAddToInventoryBatch(
                inventoryProductsToAdd,
                inventoryChanges,
            );
        });
    }

    logAddToInventoryBatch(inventoryProductsToAdd, inventoryChanges) {
        if (inventoryProductsToAdd.length > 0) {
            this.options.logDBAction({
                message:
                    'Add products to inventory - create inventory products',
                modelName: this.table,
                payload: inventoryProductsToAdd,
            });
        }

        if (inventoryChanges.length > 0) {
            this.options.logDBAction({
                message: 'Add products to inventory - create inventory changes',
                modelName: this.table,
                payload: inventoryChanges,
            });
        }
    }

    async sendToDealer(id: string, dealerId: string) {
        const shoppingListElement = await this.getByID(id);

        await this.database.write(async () => {
            await shoppingListElement.update((shoppingList) => {
                shoppingList.dealerId = dealerId;
                shoppingList.requestedSendingToDealerTime = getNowISO();
            });

            this.options.logDBAction({
                message: 'Send to dealer',
                modelName: this.table,
                payload: shoppingListElement,
            });
        });
    }

    async deleteByID(id: string) {
        const shoppingListElement = await this.getByID(id);

        const shoppingListProductService = new ShoppingListProduct({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        await this.database.write(async () => {
            await shoppingListElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete shopping list',
                modelName: this.table,
                payload: shoppingListElement,
            });

            const productsToDelete =
                await shoppingListProductService.prepareDelete(
                    shoppingListElement,
                );

            this.database.batch(...productsToDelete);

            if (productsToDelete.length > 0) {
                this.options.logDBAction({
                    message:
                        'Delete shopping list - delete shopping list products',
                    modelName: this.table,
                    payload: productsToDelete,
                });
            }
        });
    }

    async updateName(id: string, name: string) {
        const shoppingListElement = await this.getByID(id);

        await this.database.write(async () => {
            await shoppingListElement.update((shoppingList) => {
                shoppingList.name = name;
            });

            this.options.logDBAction({
                message: 'Update shopping list name',
                modelName: this.table,
                payload: shoppingListElement,
            });
        });
    }
}

export default ShoppingList;
