// Issue with watermelonDB library https://github.com/Nozbe/WatermelonDB/issues/629
// @ts-nocheck
import { Model, Q } from '@nozbe/watermelondb';
import {
    field,
    date,
    readonly,
    relation,
    lazy,
} from '@nozbe/watermelondb/decorators';
import { setGenerator } from '@nozbe/watermelondb/utils/common/randomId';
import { v4 as uuidv4 } from 'uuid';

setGenerator(uuidv4);

export default class Invoice extends Model {
    static table = 'invoices';

    static associations = {
        contacts: { type: 'belongs_to', key: 'contact_id' },
    };

    @relation('users', 'user_id') user;
    @relation('organisations', 'organisation_id') organisation;
    @relation('contacts', 'contact_id') contact;

    @field('user_id') userId;
    @field('organisation_id') organisationId;
    @field('contact_id') contactId;
    @field('external_id') externalId;
    @field('deep_link') deepLink;
    @field('number') number;
    @field('status') status;
    @field('footer_text') footerText;
    @field('sub_total') subTotal;
    @field('total_tax') totalTax;
    @field('total') total;
    @field('total_discount') totalDiscount;
    @field('sent_to_contact') sentToContact;
    @field('authorised_time') authorisedTime;
    @field('due_date_time') dueDateTime;
    @field('sent_to_contact_time') sentToContactTime;
    @field('payment_date_time') paymentDateTime;
    @field('reference') reference;
    @field('apply_taxes') applyTaxes;
    @field('attach_reports') attachReports;
    @field('public_url') publicURL;
    @field('amount_check') amountCheck;
    @field('amount_valid') amountValid;
    @field('synced') synced;
    @readonly @date('created_at') createdAt;
    @readonly @date('updated_at') updatedAt;

    @lazy
    entries = this.collections
        .get('entries')
        .query(Q.where('invoice_id', this.id));

    @lazy
    contacts = this.collections
        .get('contacts')
        .query(Q.where('id', this.contactId));
}
