import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Navigate,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { debounceTime } from 'rxjs';
import { AppAnalytics } from 'shared/analytics/app';
import App from 'shared/db/services/App';
import User from 'shared/db/services/User';
import { setMomentLocale } from 'shared/utils/date';

import {
    IntroductionModal,
    JoinTeamModal,
    Loaders,
    SessionExpiredModal,
} from '@/components';
import { SyncInfoModal } from '@/components/SyncInfoModal';
import { DB_TABLES_LISTENER } from '@/constants/dbChangesListener';
import { FIRESTORE_COLLECTION } from '@/constants/firestore';
import { useAuthContext } from '@/context/AuthContext';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useOrganisationIncomingInvitationContext } from '@/context/OrganisationIncomingInvitationContext';
import { useOrganisationsContext } from '@/context/OrganisationsContext';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { useUserContext } from '@/context/UserContext';
import { clearAppContextsState } from '@/helpers/context';
import { getRoleAllowedRoutes, isRouteMatch } from '@/helpers/router';
import { useDatabase, useNetwork, useOrganisationsLogic } from '@/hooks';
import { useRegion } from '@/hooks/useRegion';
import { ROUTE, routes } from '@/router/routes';
import { synchronizeDB } from '@/services/database/dbSync';
import { hasUnsyncedChanges } from '@/services/database/sync';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import { FirebaseRemoteConfigAPI } from '@/services/firebase/remoteConfig';
import { getLocaleFromLocalStorage } from '@/services/localization';
import Logger from '@/services/logger';
import { OrganisationsAPI } from '@/services/networking/organisations';
import { UserAPI } from '@/services/networking/user';
import Sentry from '@/services/sentry';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { FirestoreImagesDocument } from '@/types/firestore';

import TermsUpdatedDialog from './components/TermsUpdatedDialog';
import { Props } from './types';

const SecuredRoute = ({ children }: Props) => {
    const {
        maintenanceConfig: { isInMaintenance },
    } = useFeatureFlagsContext();
    const {
        invalidateUserAuth,
        isUserAuthenticated,
        isUserAfterSignUp,
        setIsUserAfterSignUp,
        setShowSessionExpiredModal,
        showSessionExpiredModal,
    } = useAuthContext();
    const {
        isInitialSyncInProgress,
        isSyncInProgress,
        setIsInitialSyncInProgress,
        setIsSyncInProgress,
        setHasSyncIssue,
        setIsSyncModalVisible,
        isSyncModalVisible,
        setIsUnsynced,
    } = useDBSyncContext();
    const { setImages, ImagesService, isImagesSyncInProgress } =
        useImagesContext();
    const {
        fetchUserOrganisationsData,
        userOrganisation,
        setUserOrganisations,
        setUserInvitations,
        isOrganisationLocalStorageEmptyOnInit,
        organisationChanged,
        setOrganisationChanged,
    } = useOrganisationsContext();
    const {
        clearDynamicLinkInvitationId,
        detectPendingTeamInvitation,
        invitationDetails,
        invitationDetailsUpdatedId,
        setInvitationDetailsUpdatedId,
    } = useOrganisationIncomingInvitationContext();
    const { setUserProfileData, userProfileData } = useUserContext();
    const { orgUser } = useOrgUserContext();

    const { getDatabase } = useDatabase();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const region = useRegion();

    const { joinOrganisation, openInvitation } = useOrganisationsLogic();

    const [roleAllowedRoutes, setRoleAllowedRoutes] = useState<ROUTE[] | null>(
        null,
    );
    const [showJoinOrganisationModal, setShowJoinOrganisationModal] =
        useState(false);
    const [showNewTermsDialog, setShowNewTermsDialog] = useState(false);
    const [showDisagreedTermsDialog, setShowDisagreedTermsDialog] =
        useState(false);
    const [termsDate, setTermsDate] = useState<string | null>(null);

    const userService = useMemo(
        () =>
            new User({
                database: getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, getDatabase],
    );

    const checkIfTermsAreAccepted = useCallback(async () => {
        if (!isInitialSyncInProgress && userProfileData?.id) {
            const [{ termsAcceptedAt, termsCreatedAt }] = await userService
                .getById(userProfileData.id)
                .fetch();
            if (termsCreatedAt) {
                const termsCreatedAtTimestamp = termsCreatedAt * 1000;
                const termsAcceptedAtTimestamp = termsAcceptedAt * 1000;

                const areTermsAccepted =
                    moment(termsAcceptedAtTimestamp).isSame(
                        moment(termsCreatedAtTimestamp),
                    ) ||
                    moment(termsAcceptedAtTimestamp).isAfter(
                        moment(termsCreatedAtTimestamp),
                    );

                if (!areTermsAccepted) {
                    setShowNewTermsDialog(true);
                    setTermsDate(moment(termsCreatedAtTimestamp).format('LL'));
                }
            }
        }
    }, [isInitialSyncInProgress, userProfileData?.id, userService]);

    useEffect(() => {
        checkIfTermsAreAccepted();
    }, [checkIfTermsAreAccepted]);

    useEffect(() => {
        if (orgUser) {
            setRoleAllowedRoutes(getRoleAllowedRoutes(routes, orgUser.role));
        }
    }, [orgUser]);

    useEffect(() => {
        const language = getLocaleFromLocalStorage();

        if (language && region) {
            setMomentLocale(language, region?.code);
        }
    }, [region]);

    const initializeAppData = useCallback(async () => {
        setIsSyncInProgress(true);

        try {
            const database = getDatabase();

            const AppService = new App({
                database,
                logInfo: Logger.logInfo,
            });

            const images = await ImagesService.getImagesCollectionDocuments();
            setImages(images);

            const userOrganisationsData =
                await OrganisationsAPI.getOrganisations();

            const userOrganisations =
                userOrganisationsData.payload.organisations;
            const userInvitations = userOrganisationsData.payload.invitations;

            setUserOrganisations(userOrganisations);
            setUserInvitations(userInvitations);

            const userProfileData = await UserAPI.getUserProfile();

            setUserProfileData(userProfileData.payload);
            Sentry.setUser(userProfileData.payload.id);

            if (isOrganisationLocalStorageEmptyOnInit) {
                setIsSyncInProgress(true);
                setIsInitialSyncInProgress(true);
                await AppService.clearDatabase();
            }

            // Fetch and activate lastest values from remote config
            await FirebaseRemoteConfigAPI.fetchAndActivateConfiguration();

            // DB synchronisation must be triggered after we receive organisations data
            // to get user's organisation id and to provide it in sync's request header.
            await synchronizeDB(database, {
                setIsLastSyncFailed: setHasSyncIssue,
            });
        } catch (error) {
            Snackbar.showToastNotification({
                message: t('Offline:notification:data_sync_failed:generic'),
                options: { variant: 'error' },
            });
        } finally {
            setIsSyncInProgress(false);
        }
    }, [
        setIsSyncInProgress,
        getDatabase,
        ImagesService,
        setImages,
        setUserInvitations,
        setUserOrganisations,
        setUserProfileData,
        isOrganisationLocalStorageEmptyOnInit,
        setHasSyncIssue,
        setIsInitialSyncInProgress,
    ]);

    useEffect(() => {
        detectPendingTeamInvitation(searchParams);
    }, [detectPendingTeamInvitation, searchParams]);

    useEffect(() => {
        if (!isUserAuthenticated) {
            return;
        }

        initializeAppData();
    }, [initializeAppData, isUserAuthenticated]);

    // Show global overlay on initial sync
    // (after signing in, coming from non secured route)
    useEffect(() => {
        if (!isSyncInProgress && orgUser) {
            setIsInitialSyncInProgress(false);

            return;
        }

        if (isSyncInProgress && orgUser == null) {
            setIsInitialSyncInProgress(true);
        }
    }, [isSyncInProgress, orgUser, setIsInitialSyncInProgress]);

    const subscribeToDbChanges = useCallback(() => {
        const database = getDatabase();

        return database
            .withChangesForTables(DB_TABLES_LISTENER)
            .pipe(debounceTime(10))
            .subscribe(async (changeSet) => {
                if (isSyncInProgress) {
                    return;
                }
                if (changeSet) {
                    setIsUnsynced(true);

                    await synchronizeDB(database, {
                        setIsInProgress: setIsSyncInProgress,
                        showPossibleErrorToastNofification: true,
                        setIsLastSyncFailed: setHasSyncIssue,
                    });

                    const isUnsyncedAfterSync = await hasUnsyncedChanges();
                    setIsUnsynced(isUnsyncedAfterSync);
                }
            });
    }, [
        getDatabase,
        isSyncInProgress,
        setIsSyncInProgress,
        setHasSyncIssue,
        setIsUnsynced,
    ]);

    // Sync when network connection get back and there is unsynced data
    const isOnline = useNetwork();

    const sync = useCallback(async () => {
        const isNotSynced = await hasUnsyncedChanges();
        if (isNotSynced) {
            const database = getDatabase();
            synchronizeDB(database, {
                setIsLastSyncFailed: setHasSyncIssue,
                showSuccessToastNotification: true,
            });
        }
    }, [getDatabase, setHasSyncIssue]);

    useEffect(() => {
        if (isOnline) {
            sync();
        }
    }, [isOnline, sync]);

    useEffect(() => {
        if (!isUserAuthenticated) {
            return;
        }

        const dbSubscriber = subscribeToDbChanges();

        return () => {
            dbSubscriber.unsubscribe();
        };
    }, [isUserAuthenticated, subscribeToDbChanges]);

    const subscribeToFirebaseImagesCollection = useCallback(() => {
        return ImagesService.subscribeToCollection<FirestoreImagesDocument>(
            FIRESTORE_COLLECTION.images,
            setImages,
        );
    }, [setImages, ImagesService]);

    useEffect(() => {
        if (!isUserAuthenticated) {
            return;
        }

        const unsubscribe = subscribeToFirebaseImagesCollection();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [isUserAuthenticated, subscribeToFirebaseImagesCollection]);

    useEffect(() => {
        if (organisationChanged) {
            const currentRoute = routes.find((route) =>
                isRouteMatch(route.path, location.pathname),
            );

            if (currentRoute && currentRoute.onInvalidRedirectTo) {
                navigate(currentRoute.onInvalidRedirectTo);
            }

            setOrganisationChanged(false);
        }
    }, [
        location.pathname,
        navigate,
        organisationChanged,
        setOrganisationChanged,
    ]);

    useEffect(() => {
        if (isInitialSyncInProgress) {
            return;
        }

        const unsubscribe = ImagesService.subscribeToSync();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [isInitialSyncInProgress, ImagesService]);

    // Alert before unload when data is not synced
    const alertUnsycedData = useCallback(
        async (event: BeforeUnloadEvent) => {
            const isUnsynced = await hasUnsyncedChanges();
            if (isUnsynced) {
                setIsSyncModalVisible(true);
                event.preventDefault();
                event.returnValue = '';
            }
        },
        [setIsSyncModalVisible],
    );

    const hideSyncModal = useCallback(() => {
        setIsSyncModalVisible(false);
    }, [setIsSyncModalVisible]);

    const closeSessionExpiredModalAndSignOutUser = useCallback(async () => {
        setShowSessionExpiredModal(false);

        invalidateUserAuth();

        AppAnalytics.logUserLoggedOutSessionExpired(FirebaseAnalytics.logEvent);
    }, [invalidateUserAuth, setShowSessionExpiredModal]);

    const closeIntroductionModal = useCallback(async () => {
        setIsUserAfterSignUp(false);
    }, [setIsUserAfterSignUp]);

    const displayJoinOrganisationModal = useCallback(
        () => setShowJoinOrganisationModal(true),
        [],
    );

    const hideJoinOrganisationModal = useCallback(
        () => setShowJoinOrganisationModal(false),
        [],
    );

    const handleJoinOrganisationCancelAction = useCallback(async () => {
        try {
            clearDynamicLinkInvitationId({ searchParams, setSearchParams });
            hideJoinOrganisationModal();

            await fetchUserOrganisationsData();
        } catch (err) {
            clearDynamicLinkInvitationId({ searchParams, setSearchParams });
            hideJoinOrganisationModal();

            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [
        clearDynamicLinkInvitationId,
        fetchUserOrganisationsData,
        hideJoinOrganisationModal,
        searchParams,
        setSearchParams,
    ]);

    const handleJoinOrganisationAcceptAction = useCallback(async () => {
        try {
            if (!invitationDetailsUpdatedId) {
                throw new Error();
            }

            await joinOrganisation(invitationDetailsUpdatedId);

            clearDynamicLinkInvitationId({ searchParams, setSearchParams });
            hideJoinOrganisationModal();
        } catch (err) {
            clearDynamicLinkInvitationId({ searchParams, setSearchParams });
            hideJoinOrganisationModal();

            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [
        clearDynamicLinkInvitationId,
        hideJoinOrganisationModal,
        invitationDetailsUpdatedId,
        joinOrganisation,
        searchParams,
        setSearchParams,
    ]);

    useEffect(() => {
        window.addEventListener('beforeunload', alertUnsycedData);

        return () => {
            window.removeEventListener('beforeunload', alertUnsycedData);
        };
    }, [alertUnsycedData]);

    useEffect(() => {
        if (!isUserAuthenticated) {
            clearAppContextsState();
        }
    }, [isUserAuthenticated]);

    // Open invitation whenever `invitationDetails` appears after detecting
    // `invitationId` url param and fetching org invitation data successfully
    useEffect(() => {
        if (
            invitationDetails &&
            !isInitialSyncInProgress &&
            userOrganisation?.id &&
            userProfileData?.id
        ) {
            openInvitation({
                id: invitationDetails.invitationId,
                organisation_id: userOrganisation.id,
                user_id: userProfileData.id,
            })
                .then((invitationId) => {
                    displayJoinOrganisationModal();
                    setInvitationDetailsUpdatedId(invitationId);
                })
                .catch((err) => {
                    clearDynamicLinkInvitationId({
                        searchParams,
                        setSearchParams,
                    });

                    if (axios.isAxiosError(err)) {
                        if (err.response?.status === 403) {
                            if (
                                err.response?.data.errors.message.includes(
                                    "You can't open your own invite",
                                )
                            ) {
                                Snackbar.showToastNotification({
                                    message: t(
                                        'Organisations:invitation_tested_info',
                                    ),
                                    options: {
                                        variant: 'info',
                                    },
                                });
                            } else {
                                Snackbar.showToastNotification({
                                    message: t(
                                        'Organisations:fetch_invitation_failed',
                                    ),
                                    options: {
                                        variant: 'error',
                                    },
                                });
                            }
                        }
                    }
                });
        }
    }, [
        clearDynamicLinkInvitationId,
        displayJoinOrganisationModal,
        invitationDetails,
        isInitialSyncInProgress,
        openInvitation,
        searchParams,
        setInvitationDetailsUpdatedId,
        setSearchParams,
        userOrganisation?.id,
        userProfileData?.id,
    ]);

    const acceptTerms = useCallback(() => {
        setShowNewTermsDialog(false);
        userService.update(userProfileData?.id ?? '', {
            termsAcceptedAt: moment().unix(),
        });
    }, [userProfileData?.id, userService]);

    const displayDisagreedTermsModal = useCallback(() => {
        setShowNewTermsDialog(false);
        setTimeout(() => setShowDisagreedTermsDialog(true), 300);
    }, []);

    const hideDisagreedTermsModal = useCallback(() => {
        setShowDisagreedTermsDialog(false);
        setTimeout(() => setShowNewTermsDialog(true), 300);
    }, []);

    if (isInMaintenance) {
        return <Navigate replace to={ROUTE.maintenance} />;
    }

    if (!isUserAuthenticated) {
        return <Navigate state={{ from: location }} to={ROUTE.signIn} />;
    }

    if (
        roleAllowedRoutes &&
        !roleAllowedRoutes.some((allowedRoute) =>
            isRouteMatch(allowedRoute, location.pathname),
        )
    ) {
        return <Navigate replace to={ROUTE.dashboard} />;
    }

    return (
        <>
            <Loaders
                isInitialSyncInProgress={isInitialSyncInProgress}
                isSyncInProgress={isSyncInProgress || isImagesSyncInProgress}
            />
            {isSyncModalVisible ? (
                <SyncInfoModal
                    isOpen
                    onButtonPress={hideSyncModal}
                    testID={'SyncInfoModal'}
                />
            ) : null}
            {isUserAuthenticated && showSessionExpiredModal ? (
                <SessionExpiredModal
                    onProceedButtonClick={
                        closeSessionExpiredModalAndSignOutUser
                    }
                />
            ) : null}
            {isUserAfterSignUp &&
            !invitationDetails &&
            userProfileData?.first_name ? (
                <IntroductionModal
                    close={closeIntroductionModal}
                    firstName={userProfileData.first_name}
                    isSyncInProgress={isSyncInProgress}
                />
            ) : null}
            {showJoinOrganisationModal && invitationDetails ? (
                <JoinTeamModal
                    name={invitationDetails?.teamName}
                    ownerName={invitationDetails.teamOwner}
                    onCancel={handleJoinOrganisationCancelAction}
                    onAccept={handleJoinOrganisationAcceptAction}
                />
            ) : null}
            {showNewTermsDialog && termsDate ? (
                <TermsUpdatedDialog
                    date={termsDate}
                    onDisagree={displayDisagreedTermsModal}
                    onConfirm={acceptTerms}
                    type="accept"
                />
            ) : null}
            {showDisagreedTermsDialog && termsDate ? (
                <TermsUpdatedDialog
                    date={termsDate}
                    onConfirm={hideDisagreedTermsModal}
                    type="block"
                />
            ) : null}
            {children}
        </>
    );
};

export default SecuredRoute;
