import { AccountingProviderType } from 'shared/types/Invoicing';

import apiClient from '@/services/networking/apiClient';
import {
    AuthorizeAccountingProviderResponsePayload,
    GetAccountingProviderSalesCodesResponsePayload,
    GetAccountingUserStatusResponsePayload,
} from '@/types/invoicing';

async function authorize(
    provider: AccountingProviderType,
): Promise<AuthorizeAccountingProviderResponsePayload> {
    const { data } =
        await apiClient.post<AuthorizeAccountingProviderResponsePayload>(
            '/accounting/authorize',
            {
                app: 'web',
                provider,
            },
        );

    return data;
}

async function getAccountingUserStatus(): Promise<GetAccountingUserStatusResponsePayload> {
    const { data } =
        await apiClient.get<GetAccountingUserStatusResponsePayload>(
            '/accounting/user_status',
        );

    return data;
}

async function getProviderSalesCodes(
    provider: AccountingProviderType,
): Promise<GetAccountingProviderSalesCodesResponsePayload> {
    const { data } =
        await apiClient.get<GetAccountingProviderSalesCodesResponsePayload>(
            '/accounting/account_codes',
            {
                params: {
                    provider,
                },
            },
        );

    return data;
}

async function triggerContactsImportFromXero(): Promise<void> {
    await apiClient.post('/accounting/import_contacts');
}

async function triggerInvoicesSyncToXero(): Promise<void> {
    await apiClient.post('/accounting/sync_invoices');
}

export const AccountingProvidersAPI = {
    authorize,
    getAccountingUserStatus,
    getProviderSalesCodes,
    triggerContactsImportFromXero,
    triggerInvoicesSyncToXero,
};
