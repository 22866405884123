import { Collection, Database, Q, Query } from '@nozbe/watermelondb';
import { Where } from '@nozbe/watermelondb/QueryDescription';
import { startsWith } from 'lodash';

import Horse from './Horse';
import HorseContact from './HorseContact';
import InventoryChange from './InventoryChange';
import InventoryProduct from './InventoryProduct';
import Organisation from './Organisation';
import Procedure from './Procedure';
import { DBServiceOptionsWithImages } from '../../types/dbService';
import {
    EntryModel,
    EntryPayload,
    EntryProcedureToAdd,
    EntryProductToAdd,
} from '../../types/Entries';
import { EntryProcedureModel } from '../../types/EntryProcedure';
import { EntryProductModel } from '../../types/EntryProduct';
import { InventoryChangeModel } from '../../types/InventoryChange';
import { InventoryProductModel } from '../../types/InventoryProduct';
import {
    getMatchingElementsFromTwoArrays,
    getUniqueElementsFromTwoArrays,
} from '../../utils/array';
import {
    applyEntriesFiltersQueries,
    applyEntriesSearchQueries,
} from '../utils';
import { getLocal } from '../../utils/date';
import { EntryQueryOptions } from '../../types/entriesFilters';
import EntryUser from './EntryUser';
import { EntryUserModel } from '../../types/EntryUser';
import { ContactModel } from '../../types/Contacts';
import { HorseModel } from '../../types/Horses';
import { FILTER_TYPE } from '../../types/filter';

class Entry {
    private database: Database;
    private collection: Collection<EntryModel>;
    private table = 'entries';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async getByParams(params = {}) {
        if (Object.keys(params).length <= 0) {
            return [];
        }

        const whereConditions: Where[] = [];
        Object.entries(params).forEach(([param, value]) => {
            // @ts-ignore
            whereConditions.push(Q.where(param, value));
        });

        return this.collection.query(...whereConditions).fetch();
    }

    async getNewestEntryByHorseID(horseID: string) {
        const [lastEntry] = await this.collection
            .query(
                Q.where('horse_id', horseID),
                Q.sortBy('logged_time', 'desc'),
                Q.take(1),
            )
            .fetch();

        return lastEntry;
    }

    getEntriesFilterQuery(options: EntryQueryOptions): [Q.Clause[], Q.Clause] {
        const { filters, invoiceOrder } = options;

        const queries: Q.Clause[] = [];

        if (filters) {
            queries.push(
                Q.experimentalJoinTables(['invoices']),
                ...applyEntriesFiltersQueries(filters),
            );
        }

        return [
            queries,
            invoiceOrder
                ? Q.sortBy('invoice_id', undefined)
                : Q.sortBy('logged_time', 'desc'),
        ];
    }

    getEntriesSearchLokiQuery(searchText: string) {
        const parsedSearchText = searchText.toLowerCase().trim();

        return applyEntriesSearchQueries(parsedSearchText);
    }

    getEntriesSearchSQLQuery(searchText: string) {
        const parsedSearchText = searchText.toLowerCase().trim();

        const sanitizedSearch = Q.sanitizeLikeString(parsedSearchText);

        if (!sanitizedSearch.length) return;

        return [
            Q.experimentalJoinTables(['horses']),
            Q.experimentalNestedJoin('horses', 'horse_contacts'),
            Q.experimentalNestedJoin('horse_contacts', 'contacts'),
            Q.or(
                Q.on(
                    'horses',
                    Q.or(
                        Q.where('name', Q.like(`${sanitizedSearch}%`)),
                        Q.where('name', Q.like(`% ${sanitizedSearch}%`)),
                    ),
                ),
                Q.on(
                    'horses',
                    Q.on(
                        'horse_contacts',
                        Q.on(
                            'contacts',
                            Q.or(
                                // @ts-ignore
                                Q.unsafeSqlExpr(
                                    `first_name || ' ' || last_name LIKE '${sanitizedSearch}%'`,
                                ),
                                Q.where(
                                    'last_name',
                                    Q.like(`${sanitizedSearch}%`),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ];
    }

    filterEntries(options: EntryQueryOptions) {
        const [filterQueries, sortQuery] = this.getEntriesFilterQuery(options);

        const searchQuery =
            options.filterType === FILTER_TYPE.LOKI
                ? this.getEntriesSearchLokiQuery(options.searchText)
                : this.getEntriesSearchSQLQuery(options.searchText);

        if (searchQuery) filterQueries.push(...searchQuery);

        return this.collection.query(...filterQueries).extend(sortQuery);
    }

    getFilteredEntriesCount(options: EntryQueryOptions) {
        const query = this.filterEntries(options);

        return query.fetchCount();
    }

    getFilteredEntries(options: EntryQueryOptions) {
        const query = this.filterEntries(options);

        return query.fetch();
    }

    // Add related products to the entry and update inventory
    async addEntryProducts(
        entry: EntryModel,
        entryProducts: (EntryProductToAdd | EntryProductModel)[],
        userId: string,
    ) {
        const entryProductsToAdd: EntryProductModel[] = [];
        const inventoryProductsToAdd: InventoryProductModel[] = [];
        const inventoryChanges: InventoryChangeModel[] = [];

        if (entry && entryProducts.length > 0) {
            const inventoryProductService = new InventoryProduct({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });
            const inventoryChangesService = new InventoryChange({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const entryProductsCollection =
                this.database.collections.get<EntryProductModel>(
                    'entry_products',
                );
            const inventoryProductsCollection =
                this.database.collections.get<InventoryProductModel>(
                    'inventory_products',
                );

            await Promise.all(
                entryProducts.map(async (entryProduct) => {
                    const inventoryProduct =
                        await inventoryProductService.getByParam(
                            'product_id',
                            entryProduct.productId,
                        );

                    if (inventoryProduct.length === 1) {
                        const inventoryChange =
                            await inventoryChangesService.prepareAdd(
                                {
                                    inventoryProductId: inventoryProduct[0].id,
                                    quantityChange: -entryProduct.quantity,
                                    processAt: new Date(entry.loggedTime),
                                },
                                userId,
                            );

                        inventoryChanges.push(inventoryChange);
                    } else {
                        const inventoryProductToAdd =
                            await inventoryProductsCollection.prepareCreate(
                                (inventoryProd) => {
                                    inventoryProd.productId =
                                        entryProduct.productId;
                                    inventoryProd.userId = entry.userId;
                                    inventoryProd.organisationId =
                                        entry.organisationId;
                                    inventoryProd.quantity = 0;
                                    inventoryProd.favourite = false;
                                },
                            );

                        inventoryProductsToAdd.push(inventoryProductToAdd);

                        const inventoryChange =
                            await inventoryChangesService.prepareAdd(
                                {
                                    inventoryProductId:
                                        inventoryProductToAdd.id,
                                    quantityChange: -entryProduct.quantity,
                                    processAt: new Date(entry.loggedTime),
                                },
                                userId,
                            );

                        inventoryChanges.push(inventoryChange);
                    }

                    const entryProductToAdd =
                        await entryProductsCollection.prepareCreate(
                            (entryProd) => {
                                entryProd.entryId = entry.id;
                                entryProd.productId = entryProduct.productId;
                                entryProd.quantity = entryProduct.quantity;
                                entryProd.userId = entry.userId;
                                entryProd.organisationId = entry.organisationId;
                            },
                        );

                    entryProductsToAdd.push(entryProductToAdd);
                }),
            );
        }

        return [
            ...inventoryProductsToAdd,
            ...entryProductsToAdd,
            ...inventoryChanges,
        ];
    }

    // Add related procedures to the entry
    async addEntryProcedures(
        entry: EntryModel,
        entryProcedures: (EntryProcedureToAdd | EntryProcedureModel)[],
    ) {
        const entryProceduresToAdd: EntryProcedureModel[] = [];

        if (entry && entryProcedures.length > 0) {
            const proceduresService = new Procedure({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const entryProceduresCollection =
                this.database.collections.get<EntryProcedureModel>(
                    'entry_procedures',
                );

            await Promise.all(
                entryProcedures.map(async (entryProcedure) => {
                    const procedure = await proceduresService.getByID(
                        entryProcedure.procedureId,
                    );

                    if (!!procedure) {
                        const entryProcedureToAdd =
                            await entryProceduresCollection.prepareCreate(
                                (entryProc) => {
                                    entryProc.entryId = entry.id;
                                    entryProc.procedureId =
                                        entryProcedure.procedureId;
                                    entryProc.price =
                                        entryProcedure.price ?? '';
                                    entryProc.quantity =
                                        entryProcedure.quantity;
                                    entryProc.name = procedure.name;
                                    entryProc.userId = entry.userId;
                                    entryProc.organisationId =
                                        entry.organisationId;
                                },
                            );

                        entryProceduresToAdd.push(entryProcedureToAdd);
                    }
                }),
            );
        }

        return entryProceduresToAdd;
    }

    async add(payload: EntryPayload, userId: string) {
        const serviceOptions: DBServiceOptionsWithImages = {
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        };
        const organisationService = new Organisation(serviceOptions);
        const entryUserService = new EntryUser(serviceOptions);

        const organisation = await organisationService.get();

        const { id: organisationID } = organisation[0];

        let createdEntry: EntryModel | undefined;

        await this.database.write(async () => {
            createdEntry = await this.collection.create((entry) => {
                entry.horse.id = payload.horse?.id;
                entry.eventID = payload.eventID;
                entry.invoiceId = payload.invoiceId;
                entry.loggedTime = payload.loggedTime;
                entry.title = payload.title;
                entry.notes = payload.notes;
                entry.privateNotes = payload.privateNotes;
                entry.userId = userId;
                entry.organisationId = organisationID;
            });

            this.options.logDBAction({
                message: 'Create entry',
                modelName: this.table,
                payload: createdEntry,
            });

            const { entryProducts, entryProcedures, membersIds } = payload;
            const entryProductsToAdd = await this.addEntryProducts(
                createdEntry,
                entryProducts,
                userId,
            );
            const entryProceduresToAdd = await this.addEntryProcedures(
                createdEntry,
                entryProcedures,
            );

            let entryUsersToAdd: EntryUserModel[] = [];
            if (membersIds) {
                entryUsersToAdd = await entryUserService.prepareAddBatch(
                    membersIds,
                    createdEntry.id,
                );
            }

            await this.database.batch(
                ...entryProductsToAdd,
                ...entryProceduresToAdd,
                ...entryUsersToAdd,
            );

            this.options.logDBAction({
                message: 'Create entry - create entry products',
                modelName: this.table,
                payload: entryProductsToAdd,
            });
            this.options.logDBAction({
                message: 'Create entry - create entry procedures',
                modelName: this.table,
                payload: entryProceduresToAdd,
            });
            this.options.logDBAction({
                message: 'Create entry - add entry users',
                modelName: this.table,
                payload: entryUsersToAdd,
            });

            const { images } = payload;

            if (images && images.length > 0) {
                await Promise.allSettled(
                    images.map(
                        async (image) =>
                            await this.options.imageService.uploadImage({
                                uploadInBackground: true,
                                image,
                                entityID: createdEntry?.id || '',
                                entityType: 'Entry',
                                annotationImage: '',
                                ownerID: userId,
                                userIDs: [userId],
                                shouldUploadImage: true,
                                organisationID,
                            }),
                    ),
                );
            }
        }, 'create-entry');

        return createdEntry;
    }

    async update(id: string, payload: EntryPayload, userId: string) {
        const serviceOptions: DBServiceOptionsWithImages = {
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        };
        const entryElement = await this.getByID(id);
        const initialLoggedTime = entryElement.loggedTime;

        const {
            entryProcedures,
            entryProducts,
            images,
            imagesToAdd,
            imagesToDelete,
            membersIds,
        } = payload;

        const proceduresService = new Procedure(serviceOptions);
        const inventoryProductService = new InventoryProduct(serviceOptions);
        const inventoryChangesService = new InventoryChange(serviceOptions);

        const entryProceduresToAdd: EntryProcedureModel[] = [];
        const entryProceduresToUpdate: EntryProcedureModel[] = [];
        const entryProceduresToDelete: EntryProcedureModel[] = [];
        const entryProductsToAdd: EntryProductModel[] = [];
        const entryProductsToUpdate: EntryProductModel[] = [];
        const entryProductsToDelete: EntryProductModel[] = [];
        const inventoryProductsToAdd: InventoryProductModel[] = [];
        const inventoryChanges: InventoryChangeModel[] = [];
        const entryUsersToUpdate: EntryUserModel[] = [];

        const updatedEntry = await this.database.write(async () => {
            const updatedEntryElement = await entryElement.update((entry) => {
                entry.horse.id = payload.horse?.id;
                // disconnect the Entry from the Event, if the date has been changed
                entry.eventID =
                    entryElement.loggedTime !== payload.loggedTime
                        ? ''
                        : entryElement.eventID;
                entry.invoiceId = payload.invoiceId || entryElement.invoiceId;
                entry.loggedTime = payload.loggedTime;
                entry.title = payload.title;
                entry.notes = payload.notes;
                entry.privateNotes = payload.privateNotes;
                entry.userId = entryElement.userId;
                entry.organisationId = entryElement.organisationId;
            });

            this.options.logDBAction({
                message: 'Update entry',
                modelName: this.table,
                payload: entryElement,
            });

            const initialEntryProcedures =
                await entryElement.entryProcedures.fetch();
            const initialEntryProducts =
                await entryElement.entryProducts.fetch();

            const entryProceduresCollection =
                this.database.collections.get<EntryProcedureModel>(
                    'entry_procedures',
                );
            const entryProductsCollection =
                this.database.collections.get<EntryProductModel>(
                    'entry_products',
                );
            const inventoryProductsCollection =
                this.database.collections.get<InventoryProductModel>(
                    'inventory_products',
                );

            await Promise.all(
                entryProcedures.map(async (entryProcedure) => {
                    const initialEntryProcedure = initialEntryProcedures.find(
                        (initialEntryProcedure) =>
                            initialEntryProcedure.procedureId ===
                            entryProcedure.procedureId,
                    );

                    if (!initialEntryProcedure) {
                        const procedure = await proceduresService.getByID(
                            entryProcedure.procedureId,
                        );

                        if (!!procedure) {
                            const entryProcedureToAdd =
                                await entryProceduresCollection.prepareCreate(
                                    (entryProc: EntryProcedureModel) => {
                                        entryProc.entryId = entryElement.id;
                                        entryProc.procedureId =
                                            entryProcedure.procedureId;
                                        entryProc.price =
                                            entryProcedure.price ?? '';
                                        entryProc.quantity =
                                            entryProcedure.quantity;
                                        entryProc.name = procedure.name;
                                        entryProc.userId = entryElement.userId;
                                        entryProc.organisationId =
                                            entryElement.organisationId;
                                    },
                                );
                            entryProceduresToAdd.push(entryProcedureToAdd);
                        }
                    }
                }),
            );

            await Promise.all(
                entryProcedures.map(async (entryProcedure) => {
                    const initialEntryProcedure = initialEntryProcedures.find(
                        (initialEntryProcedure) =>
                            initialEntryProcedure.procedureId ===
                            entryProcedure.procedureId,
                    );

                    if (
                        initialEntryProcedure &&
                        (initialEntryProcedure.quantity !==
                            entryProcedure.quantity ||
                            initialEntryProcedure.price !==
                                entryProcedure.price)
                    ) {
                        const entryProcedureToUpdate =
                            await initialEntryProcedure.prepareUpdate(
                                (entryProc) => {
                                    entryProc.price =
                                        entryProcedure.price || entryProc.price;
                                    entryProc.quantity =
                                        entryProcedure.quantity;
                                },
                            );

                        entryProceduresToUpdate.push(entryProcedureToUpdate);
                    }
                }),
            );

            await Promise.all(
                initialEntryProcedures.map(async (initialEntryProcedure) => {
                    if (
                        !entryProcedures.find(
                            (entryProcedure) =>
                                entryProcedure.procedureId ===
                                initialEntryProcedure.procedureId,
                        )
                    ) {
                        entryProceduresToDelete.push(
                            initialEntryProcedure.prepareMarkAsDeleted(),
                        );
                    }
                }),
            );

            await Promise.all(
                entryProducts.map(async (entryProduct) => {
                    const initialEntryProduct = initialEntryProducts.find(
                        (initialEntryProduct) =>
                            initialEntryProduct.productId ===
                            entryProduct.productId,
                    );

                    if (!initialEntryProduct) {
                        const inventoryProduct =
                            await inventoryProductService.getByParam(
                                'product_id',
                                entryProduct.productId,
                            );
                        if (inventoryProduct.length === 1) {
                            const inventoryChange =
                                await inventoryChangesService.prepareAdd(
                                    {
                                        inventoryProductId:
                                            inventoryProduct[0].id,
                                        quantityChange: -entryProduct.quantity,
                                        processAt: new Date(
                                            entryElement.loggedTime,
                                        ),
                                    },
                                    userId,
                                );

                            inventoryChanges.push(inventoryChange);
                        } else {
                            const inventoryProductToAdd =
                                await inventoryProductsCollection.prepareCreate(
                                    (inventoryProd: InventoryProductModel) => {
                                        inventoryProd.productId =
                                            entryProduct.productId;
                                        inventoryProd.userId =
                                            entryElement.userId;
                                        inventoryProd.organisationId =
                                            entryElement.organisationId;
                                        inventoryProd.quantity = 0;
                                        inventoryProd.favourite = false;
                                    },
                                );

                            inventoryProductsToAdd.push(inventoryProductToAdd);

                            const inventoryChange =
                                await inventoryChangesService.prepareAdd(
                                    {
                                        inventoryProductId:
                                            inventoryProductToAdd.id,
                                        quantityChange: -entryProduct.quantity,
                                        processAt: new Date(
                                            entryElement.loggedTime,
                                        ),
                                    },
                                    userId,
                                );

                            inventoryChanges.push(inventoryChange);
                        }

                        const entryProductToAdd =
                            await entryProductsCollection.prepareCreate(
                                (entryProd) => {
                                    entryProd.entryId = entryElement.id;
                                    entryProd.productId =
                                        entryProduct.productId;
                                    entryProd.quantity = entryProduct.quantity;
                                    entryProd.userId = entryElement.userId;
                                    entryProd.organisationId =
                                        entryElement.organisationId;
                                },
                            );
                        entryProductsToAdd.push(entryProductToAdd);
                    } else {
                        if (
                            initialEntryProduct.quantity !==
                            entryProduct.quantity
                        ) {
                            const inventoryProduct =
                                await inventoryProductService.getByParam(
                                    'product_id',
                                    entryProduct.productId,
                                );

                            if (inventoryProduct.length === 1) {
                                const inventoryChange =
                                    await inventoryChangesService.prepareAdd(
                                        {
                                            inventoryProductId:
                                                inventoryProduct[0].id,
                                            quantityChange:
                                                initialEntryProduct.quantity -
                                                entryProduct.quantity,
                                            processAt: new Date(
                                                entryElement.loggedTime,
                                            ),
                                        },
                                        userId,
                                    );

                                inventoryChanges.push(inventoryChange);
                            }

                            const entryProductToUpdate =
                                await initialEntryProduct.prepareUpdate(
                                    (entryProd) => {
                                        entryProd.quantity =
                                            entryProduct.quantity;
                                    },
                                );
                            entryProductsToUpdate.push(entryProductToUpdate);
                        }
                    }
                }),
            );

            await Promise.all(
                initialEntryProducts.map(async (initialEntryProduct) => {
                    if (
                        !entryProducts.find(
                            (entryProduct) =>
                                entryProduct.productId ===
                                initialEntryProduct.productId,
                        )
                    ) {
                        entryProductsToDelete.push(
                            initialEntryProduct.prepareMarkAsDeleted(),
                        );
                        const inventoryProduct =
                            await inventoryProductService.getByParam(
                                'product_id',
                                initialEntryProduct.productId,
                            );
                        if (inventoryProduct.length === 1) {
                            const inventoryChange =
                                await inventoryChangesService.prepareAdd(
                                    {
                                        inventoryProductId:
                                            inventoryProduct[0].id,
                                        quantityChange:
                                            initialEntryProduct.quantity,
                                        processAt: new Date(initialLoggedTime),
                                    },
                                    userId,
                                );

                            inventoryChanges.push(inventoryChange);
                        }
                    } else if (initialLoggedTime !== entryElement.loggedTime) {
                        const revert =
                            await this.prepareRevertInventoryChangeForEntryProduct(
                                initialEntryProduct,
                                initialLoggedTime,
                                entryElement.loggedTime,
                                userId,
                            );

                        inventoryChanges.push(...revert);
                    }
                }),
            );

            // update entry users
            if (membersIds) {
                const entryUserService = new EntryUser(serviceOptions);
                const currentEntryUsers = await entryUserService
                    .getByEntryId(id)
                    .fetch();
                const currentUsersIDs = currentEntryUsers.map(
                    (currentUser) => currentUser.userId,
                );

                const membersIdsToDelete = await currentEntryUsers
                    .filter(
                        (currentEntryUser) =>
                            !membersIds.includes(currentEntryUser.userId),
                    )
                    .map((entryUser) => entryUser.id);

                const membersIdsToAdd = membersIds.filter(
                    (memberId) => !currentUsersIDs.includes(memberId),
                );

                const membersToDelete =
                    await entryUserService.prepareDeleteBatch(
                        membersIdsToDelete,
                    );
                const membersToAdd = await entryUserService.prepareAddBatch(
                    membersIdsToAdd,
                    id,
                );
                entryUsersToUpdate.push(...membersToDelete);
                entryUsersToUpdate.push(...membersToAdd);
            }

            this.database.batch(
                ...entryProceduresToAdd,
                ...entryProceduresToUpdate,
                ...entryProceduresToDelete,
                ...entryProductsToAdd,
                ...entryProductsToUpdate,
                ...entryProductsToDelete,
                ...inventoryProductsToAdd,
                ...inventoryChanges,
                ...entryUsersToUpdate,
            );

            this.logUpdateBatch({
                entryProceduresToAdd,
                entryProceduresToUpdate,
                entryProceduresToDelete,
                entryProductsToAdd,
                entryProductsToUpdate,
                entryProductsToDelete,
                inventoryProductsToAdd,
                inventoryChanges,
                entryUsersToUpdate,
            });

            return updatedEntryElement;
        }, 'update-entry');

        if (imagesToDelete && imagesToDelete.length > 0) {
            imagesToDelete.map(async (docID) => {
                await this.options.imageService.removeByDocId(docID);
            });
        }

        if (images && images.length > 0) {
            const organisationService = new Organisation({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const organisation = await organisationService.get();
            const { id: organisationID } = organisation[0];

            images.map(async (image) => {
                await this.options.imageService.uploadImage({
                    image,
                    entityID: id,
                    entityType: 'Entry',
                    annotationImage: '',
                    ownerID: userId,
                    userIDs: [userId],
                    documentID: image.documentID,
                    organisationID,
                    uploadInBackground: true,
                    shouldUploadImage: !!imagesToAdd
                        ? !startsWith(image.uri, 'http') ||
                          imagesToAdd.some((item) => item.uri === image.uri)
                        : false,
                });
            });
        }

        return updatedEntry;
    }

    logUpdateBatch({
        entryProceduresToAdd,
        entryProceduresToUpdate,
        entryProceduresToDelete,
        entryProductsToAdd,
        entryProductsToUpdate,
        entryProductsToDelete,
        inventoryProductsToAdd,
        inventoryChanges,
        entryUsersToUpdate,
    }) {
        this.options.logDBAction({
            message: 'Entry update - create entry procedures',
            modelName: this.table,
            payload: entryProceduresToAdd,
        });
        this.options.logDBAction({
            message: 'Entry update - update entry procedures',
            modelName: this.table,
            payload: entryProceduresToUpdate,
        });
        this.options.logDBAction({
            message: 'Entry update - delete entry procedures',
            modelName: this.table,
            payload: entryProceduresToDelete,
        });
        this.options.logDBAction({
            message: 'Entry update - create entry products',
            modelName: this.table,
            payload: entryProductsToAdd,
        });
        this.options.logDBAction({
            message: 'Entry update - update entry products',
            modelName: this.table,
            payload: entryProductsToUpdate,
        });
        this.options.logDBAction({
            message: 'Entry update - delete entry products',
            modelName: this.table,
            payload: entryProductsToDelete,
        });
        this.options.logDBAction({
            message: 'Entry update - create inventory products',
            modelName: this.table,
            payload: inventoryProductsToAdd,
        });
        this.options.logDBAction({
            message: 'Entry update - inventory changes',
            modelName: this.table,
            payload: inventoryChanges,
        });
        this.options.logDBAction({
            message: 'Entry update - entry users',
            modelName: this.table,
            payload: entryUsersToUpdate,
        });
    }

    async prepareRevertInventoryChangeForEntryProduct(
        entryProduct: EntryProductModel,
        oldLoggedTime: string,
        newLoggedTime: string,
        userId: string,
    ) {
        const inventoryChangesService = new InventoryChange({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const inventoryProductService = new InventoryProduct({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const inventoryProduct = await inventoryProductService.getByParam(
            'product_id',
            entryProduct.productId,
        );

        const revertChange = await inventoryChangesService.prepareAdd(
            {
                inventoryProductId: inventoryProduct[0].id,
                quantityChange: entryProduct.quantity,
                processAt: new Date(oldLoggedTime),
            },
            userId,
        );

        const newChange = await inventoryChangesService.prepareAdd(
            {
                inventoryProductId: inventoryProduct[0].id,
                quantityChange: -entryProduct.quantity,
                processAt: new Date(newLoggedTime),
            },
            userId,
        );

        return [revertChange, newChange];
    }

    async bump(ids: string[]) {
        await this.database.write(async () => {
            const entryElements = await Promise.all(
                ids.map(async (id) => this.getByID(id)),
            );

            const updates = entryElements.map((entryElement: EntryModel) =>
                entryElement.prepareUpdate((procedure: EntryModel) => {
                    procedure.title = entryElement.title;
                }),
            );

            this.database.batch(...updates);

            this.options.logDBAction({
                message: 'Entries bump',
                modelName: this.table,
                payload: updates,
            });
        });
    }

    async deleteByID(id: string, userId: string) {
        const entryUserService = new EntryUser({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        await this.database.write(async () => {
            const entryDeleteBatch = await this.prepareDeleteById(id, userId);

            this.database.batch(...entryDeleteBatch);

            this.options.logDBAction({
                message:
                    'Delete entry - delete entry procedures, entry products and entry',
                modelName: this.table,
                payload: entryDeleteBatch,
            });

            await entryUserService.deleteAllUsersByEntryId(id);
        });

        this.options.imageService.remove(id);
    }

    async prepareDeleteById(id: string, userId: string) {
        const entryElement = await this.getByID(id);
        const entryProcedures = await entryElement.entryProcedures.fetch();
        const entryProducts = await entryElement.entryProducts.fetch();

        const inventoryProductService = new InventoryProduct({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const inventoryChangesService = new InventoryChange({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const entryProceduresToDelete: EntryProcedureModel[] = [];
        const entryProductsToDelete: EntryProductModel[] = [];
        const inventoryChanges: InventoryChangeModel[] = [];

        const entryDeletePrepare = entryElement.prepareMarkAsDeleted();

        if (entryProcedures.length > 0) {
            await Promise.all(
                entryProcedures.map(async (entryProcedure) => {
                    entryProceduresToDelete.push(
                        entryProcedure.prepareMarkAsDeleted(),
                    );
                }),
            );
        }

        if (entryProducts.length > 0) {
            await Promise.all(
                entryProducts.map(async (entryProduct) => {
                    const inventoryProduct =
                        await inventoryProductService.getByParam(
                            'product_id',
                            entryProduct.productId,
                        );
                    if (inventoryProduct.length === 1) {
                        const inventoryChange =
                            await inventoryChangesService.prepareAdd(
                                {
                                    inventoryProductId: inventoryProduct[0].id,
                                    quantityChange: entryProduct.quantity,
                                    processAt: new Date(
                                        entryElement.loggedTime,
                                    ),
                                },
                                userId,
                            );

                        inventoryChanges.push(inventoryChange);
                    }
                    entryProductsToDelete.push(
                        entryProduct.prepareMarkAsDeleted(),
                    );
                }),
            );
        }

        return [
            ...entryProceduresToDelete,
            ...entryProductsToDelete,
            entryDeletePrepare,
        ];
    }

    async getEntriesWithoutHorseContact() {
        const horseService = new Horse({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });
        const horseContactService = new HorseContact({
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        });

        const allHorses = await horseService.getAll();
        const allHorseContacts = await horseContactService.getAll();
        const allEntries = await this.getAll();

        const horsesWithoutContacts = getUniqueElementsFromTwoArrays(
            allHorses,
            allHorseContacts,
            'id',
            'horseId',
        );

        return getMatchingElementsFromTwoArrays(
            allEntries,
            horsesWithoutContacts,
            'horseId',
            'id',
        );
    }

    getEntriesWithoutHorses() {
        return this.collection.query(Q.where('horse_id', null)).fetch();
    }

    getEntriesForHorsesQuery(horses: HorseModel[]): Query<EntryModel> {
        const horsesIds = horses.map((horse) => horse.id);

        const query = Q.or(
            ...horsesIds.map((id) => Q.where('horse_id', id)),
            Q.where('horse_id', null),
        );

        return this.collection.query(query);
    }

    async getEntriesForContact(contact: ContactModel) {
        const horses = await contact.horses.fetch();
        const query = this.getEntriesForHorsesQuery(horses);
        return query.fetch();
    }

    getEntriesByHorseName = (horsesIds: string[], name: string) => {
        return this.collection.query(
            Q.on(
                'horses',
                Q.and(
                    Q.where('id', Q.oneOf(horsesIds)),
                    Q.where('name', Q.like(`%${Q.sanitizeLikeString(name)}%`)),
                ),
            ),
        );
    };

    getEntriesIncludingHorseName = (horsesIds: string[], name: string) => {
        return this.collection.query(
            Q.on(
                'horses',
                Q.and(
                    Q.where('id', Q.oneOf(horsesIds)),
                    Q.where('name', Q.like(`%${Q.sanitizeLikeString(name)}%`)),
                ),
            ),
        );
    };

    getEntriesByContactName = (name: string) => {
        return this.collection.query(
            Q.experimentalJoinTables(['horses']),
            Q.experimentalNestedJoin('horses', 'horse_contacts'),
            Q.experimentalNestedJoin('horse_contacts', 'contacts'),
            Q.unsafeSqlQuery(
                'select entries.* from entries ' +
                    'left join horses on entries.horse_id is horses.id ' +
                    'left join horse_contacts on horses.id is horse_contacts.horse_id ' +
                    'left join contacts on horse_contacts.contact_id is contacts.id ' +
                    `where contacts.first_name || ' ' || contacts.last_name like '%${Q.sanitizeLikeString(
                        name,
                    )}%' or contacts.first_name like '%${Q.sanitizeLikeString(
                        name,
                    )}%' or contacts.last_name like '%${Q.sanitizeLikeString(
                        name,
                    )}%' and contacts._status is not 'deleted'
                        and horse_contacts._status is not 'deleted'
                        and horses._status is not 'deleted'
                        and entries._status is not 'deleted'`,
            ),
        );
    };

    getEntriesToInvoiceCount() {
        const today = getLocal();

        const tomorrow = today.plus({ days: 1 });
        const formattedTomorrow = Q.sanitizeLikeString(
            tomorrow.toISO().split('T')[0],
        ).replace(/_/g, '-');

        return this.collection
            .query(
                Q.where('invoice_id', ''),
                Q.where('logged_time', Q.lt(formattedTomorrow)),
            )
            .observeCount();
    }

    getRecentlyUpdatedEntries(limit = 5) {
        return this.collection.query(
            Q.sortBy('updated_at', Q.desc),
            Q.take(limit),
        );
    }
}

export default Entry;
