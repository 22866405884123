import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { ContactModel } from 'shared/types/Contacts';
import { EntryModel } from 'shared/types/Entries';
import { OrganisationAccountingsModel } from 'shared/types/OrganisationAccountings';

import { ContactsSection, EntriesSection } from '@/components';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import { HeaderSection, InvoiceHistory } from './components';
import { Props } from './types';

const sectionsRowStyles = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
    gap: 3,
    flex: 1,
};

const sectionStyles = {
    minWidth: 400,
};

function InvoiceDetails({ invoice, isLoading, refetchInvoice }: Props) {
    const [contacts, setContacts] = useState<ContactModel[]>([]);
    const [entries, setEntries] = useState<EntryModel[]>([]);
    const [organisationAccountingsData, setOrganisationAccountingsData] =
        useState<OrganisationAccountingsModel[]>([]);

    const db = useDatabase();

    const database = useMemo(() => db.getDatabase(), [db]);

    const accountingsService = useMemo(
        () =>
            new OrganisationAccountings({
                database,
                logDBAction: Logger.logRecordActivity,
            }),
        [database],
    );

    const fetchData = useCallback(async () => {
        if (!invoice) {
            return;
        }

        const contacts = await invoice.contacts.fetch();
        const entries = await invoice.entries.fetch();
        const organisationAccountingsData = await accountingsService
            .get()
            .fetch();
        setContacts(contacts);
        setEntries(entries);
        setOrganisationAccountingsData(organisationAccountingsData);
    }, [accountingsService, invoice]);

    useEffect(() => {
        fetchData();
    }, [fetchData, invoice]);

    return (
        <Box>
            <HeaderSection
                invoice={invoice}
                refetchInvoice={refetchInvoice}
                isLoading={isLoading}
                accountings={organisationAccountingsData[0]}
                contact={contacts ? contacts[0] : null}
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                <Box sx={sectionsRowStyles}>
                    <EntriesSection
                        entries={entries || []}
                        isLoading={isLoading}
                    />
                </Box>
                <Box sx={sectionsRowStyles}>
                    <Box sx={sectionStyles}>
                        <ContactsSection
                            testIdPrefix={'InvoiceContactsSection'}
                            contacts={contacts || []}
                            isLoading={isLoading}
                        />
                    </Box>
                    <Box sx={sectionStyles}>
                        <InvoiceHistory invoice={invoice} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default InvoiceDetails;
