import { Typography } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { of } from 'rxjs/internal/observable/of';
import { switchMap } from 'rxjs/operators';
import Database from 'shared/db/services/Database.web';
import { ROLE } from 'shared/types/OrganisationUser';
import { ProcedureModel } from 'shared/types/Procedures';

import {
    CameraIcon,
    HorseIcon,
    InvoiceIcon,
    PersonIcon,
    ScheduleIcon,
} from '@/assets/svg';
import {
    OrganisationMembersAvatarGroup,
    SelectableListItem,
} from '@/components';
import { useOrgUserContext } from '@/context/OrgUserContext';
import { useFormatDate } from '@/hooks';
import { ProceduresBadge } from '@/pages/EntriesPage/components/EntriesTable/components/EntriesTableItem/components';
import { ROUTE } from '@/router/routes';
import { COLOR } from '@/theme/colors';
import { getTitle } from '@/utils/entry';
import { getRoutePath } from '@/utils/router';

import { Props } from './types';

function EntriesListItem({
    avatarUrl,
    clickable,
    entry,
    event,
    entryProcedures,
    horse,
    contacts,
    isSelected,
    onClick,
    onRemove,
    users,
    removable,
    selectable,
    size,
    testID,
    wrapperStyles,
    multiSelect = true,
    selectCondition,
    conditionWarning,
}: Props) {
    const { orgUser } = useOrgUserContext();
    const database = Database.getDatabase();
    const formatDate = useFormatDate();
    const navigate = useNavigate();

    const navigateToEvent = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            if (!event) {
                return null;
            }

            const route = getRoutePath(ROUTE.appointment, { id: event.id });

            navigate(route);
        },
        [event, navigate],
    );

    const procedures = useMemo(() => {
        if (!entryProcedures || !entryProcedures.length) {
            return [];
        }

        return entryProcedures.map((ep) => {
            return {
                id: ep.id,
                name: ep.name,
            } as ProcedureModel;
        });
    }, [entryProcedures]);

    const bottomContent = useCallback(() => {
        return (
            <div className="flex flex-row items-center py-2">
                {entry.invoiceId && orgUser?.role === ROLE.admin ? (
                    <div className="pr-[5px]">
                        <InvoiceIcon width={26} height={26} />
                    </div>
                ) : null}
                <ProceduresBadge procedures={procedures} />
                {event ? (
                    <div onClick={navigateToEvent} className="pr-2">
                        <ScheduleIcon
                            width={24}
                            height={24}
                            color={COLOR.paleSky}
                        />
                    </div>
                ) : null}
            </div>
        );
    }, [entry.invoiceId, event, navigateToEvent, orgUser?.role, procedures]);

    return (
        <SelectableListItem
            clickable={clickable}
            testID={`${testID}-${entry.title}`}
            avatarSize={size}
            item={entry}
            onRemove={onRemove}
            wrapperStyles={wrapperStyles}
            selectable={selectable}
            onClick={onClick}
            avatarUrl={avatarUrl}
            isSelected={isSelected}
            avatarVariant="rounded"
            removable={removable}
            placeholder={<CameraIcon color={COLOR.mischka} />}
            multiSelect={multiSelect}
            selectCondition={selectCondition}
            conditionWarning={conditionWarning}
            renderExtraContentComponent={
                !selectable ? bottomContent : undefined
            }
        >
            <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <div className="text-sm font-normal text-vscBlack50 leading-4">
                        {formatDate(entry.loggedTime, 'shortdatetime')}
                    </div>
                    <div className="items-start">
                        <OrganisationMembersAvatarGroup
                            database={database}
                            max={2}
                            users={users}
                            size={20}
                            fontSize={8}
                            testID="SchedulesListItem-Members"
                        />
                    </div>
                </div>
                <div>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            lineHeight: '24px',
                            color: COLOR.vividBlack,
                        }}
                    >
                        {getTitle(entry.title)}
                    </Typography>
                </div>
                <div className="flex flex-row flex-1">
                    <div className="flex flex-row items-center shrink">
                        <div className="pr-1">
                            <HorseIcon width={14} height={14} />
                        </div>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: !entry.horseId ? 700 : 400,
                                lineHeight: '19.5px',
                                color: !entry.horseId
                                    ? COLOR.darkYellow
                                    : COLOR.vscBlack,
                                flexShrink: 1,
                                flexGrow: 1,
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}
                        >
                            {entry.horseId ? horse?.name : 'No horse selected'}
                        </Typography>
                    </div>
                    {entry.horseId && contacts.length ? (
                        <div className="flex flex-row items-center shrink grow basis-[30%] ml-[5px]">
                            <div className="pr-1">
                                <PersonIcon width={14} height={14} />
                            </div>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    lineHeight: '19.5px',
                                    flexShrink: 1,
                                    flexGrow: 1,
                                    color: COLOR.vscBlack,
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                }}
                            >
                                {contacts[0].firstName}
                            </Typography>
                        </div>
                    ) : null}
                </div>
            </div>
        </SelectableListItem>
    );
}

const enhance = withObservables<Props, unknown>(
    ['entry'],
    ({ entry }: Props) => {
        const database = Database.getDatabase();

        const users = database.collections
            .get('users')
            .query(Q.where('id', entry.userId));

        const horse = entry.horse.observe();
        const event = entry.eventID ? entry.event.observe() : of(null);

        return {
            entryProcedures: entry.entryProcedures.observe(),
            horse,
            contacts: horse.pipe(
                switchMap((horse) =>
                    entry.horseId ? horse.contacts.observe() : of([]),
                ),
            ),
            users,
            event,
        };
    },
);

export default enhance(EntriesListItem);
