import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CollapsibleContainer } from '@/components';
import { ROUTE } from '@/router/routes';

const buttonStyles = {
    alignSelf: 'center',
    minWidth: 175,
};

function InvoicingAndAdministrationSection() {
    const { t } = useTranslation();

    return (
        <CollapsibleContainer
            title={t('Options:button:invoicing_administration')}
            testID="SettingsPage-InvoicingAndAdministration"
        >
            <Alert severity="info" sx={{ mb: 3 }}>
                {t('Settings:invoices_admin:heading_explanation')}
            </Alert>
            <Box sx={{ display: 'flex', gap: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: 'column',
                        flex: 2,
                    }}
                >
                    <Typography fontWeight="bold" fontSize={14}>
                        {t(
                            'Settings:invoices_admin:configure_procedures_header',
                        )}
                    </Typography>
                    <Typography fontSize={14}>
                        {t(
                            'Settings:invoices_admin:configure_procedures_explanation',
                        )}
                    </Typography>
                </Box>
                <Button
                    component={Link}
                    to={ROUTE.procedures}
                    variant="contained"
                    sx={buttonStyles}
                    data-test-id="InvoicingAndAdministrationSection-ConfigureProceduresButton"
                >
                    {t('Settings:invoices_admin:configure_procedures_action')}
                </Button>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Box sx={{ display: 'flex', gap: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: 'column',
                        flex: 2,
                    }}
                >
                    <Typography fontWeight="bold" fontSize={14}>
                        {t('Settings:invoices_admin:connect_provider_header')}
                    </Typography>
                    <Typography fontSize={14}>
                        {t(
                            'Settings:invoices_admin:connect_provider_explanation_xero_only',
                        )}
                    </Typography>
                </Box>
                <Button
                    component={Link}
                    to={ROUTE.accountingProviders}
                    variant="contained"
                    sx={buttonStyles}
                    data-test-id="InvoicingAndAdministrationSection-ConnectToProviderButton"
                >
                    {t('Settings:invoices_admin:connect_provider_action')}
                </Button>
            </Box>
        </CollapsibleContainer>
    );
}

export default InvoicingAndAdministrationSection;
