import { Add } from '@mui/icons-material';
import {
    Box,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProductTypesTranslationKeys } from 'shared/constants/products/translations';
import InventoryProductService from 'shared/db/services/InventoryProduct';
import Product from 'shared/db/services/Product';
import User from 'shared/db/services/User';
import { InventoryFiltersObject } from 'shared/types/inventoryFilters';
import { InventoryProduct, ProductType } from 'shared/types/Products';
import { RegionModel } from 'shared/types/Region';
import { getInventoryProducts } from 'shared/utils/inventory';
import { getAllSizesGrouped } from 'shared/utils/product';

import {
    Button,
    EmptyState,
    RemoveEntityAlert,
    SelectedProductsListSkeleton,
} from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useInventoryFiltersContext } from '@/context/InventoryFiltersContext';
import { usePageScrollableElementRef } from '@/context/PageScrollableElementRefContext';
import { useUserContext } from '@/context/UserContext';
import { useDatabase } from '@/hooks';
import { useAddProductsToInventory } from '@/hooks/useAddProductsToInventory';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { COLOR } from '@/theme/colors';

import { InventoryProductsList } from './components';
import { AddInventoryProductModal } from './components/AddInventoryProductModal';

function InventoryPage() {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { filtersState, setFiltersState } = useInventoryFiltersContext();
    const { userProfileData } = useUserContext();
    const { ImagesService } = useImagesContext();
    const { scrollableElementRef } = usePageScrollableElementRef();
    const { isInitialSyncInProgress, isSyncInProgress } = useDBSyncContext();

    const handleAddProductsToInventory = useAddProductsToInventory();

    const [isInventoryDataLoading, setIsInventoryDataLoading] = useState(false);
    const [filteredInventoryProducts, setFilteredInventoryProducts] = useState<
        InventoryProduct[]
    >([]);
    const [productToRemove, setProductToRemove] =
        useState<InventoryProduct | null>(null);
    const [paginatedInventoryProducts, setPaginatedInventoryProducts] =
        useState<InventoryProduct[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loadedPage, setLoadedPage] = useState<number>(1);
    const [isAddProductModalVisible, setIsAddProductModalVisible] =
        useState(false);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const productService = useMemo(
        () =>
            new Product({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
                logError: Logger.logError,
            }),
        [ImagesService, database],
    );

    const inventoryProductService = useMemo(
        () =>
            new InventoryProductService({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const productToRemoveCompleteName = useMemo(
        () =>
            `${productToRemove?.name ?? ''} ${
                productToRemove?.materialShapeAndClips ?? ''
            }`.trim(),
        [productToRemove?.materialShapeAndClips, productToRemove?.name],
    );

    const hasMorePages = useMemo(
        () =>
            paginatedInventoryProducts.length <
            filteredInventoryProducts.length,
        [filteredInventoryProducts, paginatedInventoryProducts.length],
    );

    const nextPage = useCallback(() => {
        setCurrentPage((page) =>
            hasMorePages || page === 0 ? page + 1 : page,
        );
    }, [hasMorePages]);

    const loadPage = useCallback(
        (page: number) => {
            setPaginatedInventoryProducts(
                filteredInventoryProducts.slice(0, page * 20),
            );
            setLoadedPage(page);
            setIsInventoryDataLoading(false);
        },
        [filteredInventoryProducts],
    );

    useEffect(() => {
        if (filteredInventoryProducts.length && loadedPage !== currentPage) {
            loadPage(currentPage);
        }
    }, [currentPage, filteredInventoryProducts.length, loadPage, loadedPage]);

    const fetchProducts = useCallback(
        async (filters: InventoryFiltersObject) => {
            const userService = new User({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            const inventoryProductSizes =
                await inventoryProductService.getFilteredProducts(filters);

            const productSizesInInventory = await productService.getByIDs(
                inventoryProductSizes.map((invProd) => invProd.productId),
            );

            const allGroupedProductsFromInventory = await getAllSizesGrouped(
                productSizesInInventory,
                productService,
            );

            const regions = await database.collections.get<RegionModel>(
                'regions',
            );

            const inventoryProducts = await getInventoryProducts({
                groupedProducts: allGroupedProductsFromInventory,
                inventoryProducts: inventoryProductSizes,
                regions,
                userId: userProfileData?.id ?? '',
                userService,
                productService,
            });

            setFilteredInventoryProducts(inventoryProducts);

            if (!inventoryProducts.length) {
                setPaginatedInventoryProducts([]);
                setIsInventoryDataLoading(false);
            }
        },
        [
            database,
            ImagesService,
            inventoryProductService,
            productService,
            userProfileData?.id,
        ],
    );

    const refetchProducts = useCallback(async () => {
        setIsInventoryDataLoading(true);
        await fetchProducts(filtersState);
        setLoadedPage(0);
    }, [fetchProducts, filtersState]);

    const handleFilterChange = useCallback(
        (_, type) => {
            setFiltersState((filtersState) => {
                if (filtersState.type !== type) {
                    setLoadedPage(1);
                    setCurrentPage(1);
                    return {
                        ...filtersState,
                        type,
                    };
                }

                return filtersState;
            });
        },
        [setFiltersState],
    );

    useEffect(() => {
        if (!isInitialSyncInProgress && !isSyncInProgress) {
            refetchProducts();
        }
    }, [isInitialSyncInProgress, refetchProducts, isSyncInProgress]);

    const showLoadingState =
        (!paginatedInventoryProducts?.length && isInventoryDataLoading) ||
        isInitialSyncInProgress;

    const emptyStateMessages: {
        [key: string]: string;
    } = {
        [ProductType.shoes]: t(
            'Inventory:empty_state:no_shoes_have_been_added',
        ),
        [ProductType.nails]: t(
            'Inventory:empty_state:no_nails_have_been_added',
        ),
        [ProductType.rasps]: t(
            'Inventory:empty_state:no_rasps_have_been_added',
        ),
        [ProductType.tools]: t(
            'Inventory:empty_state:no_tools_have_been_added',
        ),
        [ProductType.other]: t(
            'Inventory:empty_state:no_other_products_have_been_added',
        ),
        [ProductType.custom]: t(
            'Inventory:empty_state:no_custom_have_been_added',
        ),
    };

    const handleRemoveButtonClick = useCallback(
        (name: string, materialShapeAndClips: string | null) => {
            const clickedInventoryProduct = filteredInventoryProducts.find(
                (inventoryProduct) =>
                    inventoryProduct.name === name &&
                    materialShapeAndClips === materialShapeAndClips,
            );

            if (clickedInventoryProduct) {
                setProductToRemove(clickedInventoryProduct);
            }
        },
        [filteredInventoryProducts],
    );

    const closeRemoveProductAlert = useCallback(
        () => setProductToRemove(null),
        [],
    );

    const removeProduct = useCallback(async () => {
        if (productToRemove?.productSizes) {
            try {
                if (productToRemove) {
                    for (const size of productToRemove.productSizes) {
                        if (size.inventoryProductId) {
                            await inventoryProductService.deleteByID(
                                size.inventoryProductId,
                            );
                        }
                    }
                }

                Snackbar.showToastNotification({
                    message: t('App:Messages:has_been_removed_successfully', {
                        entity: t(productToRemoveCompleteName),
                    }),
                });

                await refetchProducts();
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        }
    }, [
        inventoryProductService,
        productToRemove,
        productToRemoveCompleteName,
        refetchProducts,
        t,
    ]);

    const openAddProductModal = useCallback(() => {
        setIsAddProductModalVisible(true);
    }, []);

    const handleAddProductModalClose = useCallback(() => {
        setIsAddProductModalVisible(false);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'max-content',
                gap: 4,
                mb: 3,
                flex: 1,
                minWidth: 650,
            }}
        >
            <Button
                caption={t('Inventory:button:add_product_web')}
                onClick={openAddProductModal}
                startIcon={<Add />}
                testID="InventoryPage-AddProductButton"
                sx={{
                    alignSelf: 'flex-end',
                }}
                color="primary"
                variant={'contained'}
            />
            <ToggleButtonGroup
                value={filtersState}
                exclusive
                onChange={handleFilterChange}
                aria-label="left aligned"
                sx={{ display: 'flex', gap: 2 }}
                data-test-id="InventoryPage-Filters"
            >
                {Object.values(ProductType).map((type) => (
                    <ToggleButton
                        value={type}
                        key={type}
                        data-test-id={`InventoryPage-Filter${type}`}
                        className={`${
                            type === filtersState.type ? 'bg-lightBlue' : ''
                        } rounded-lg capitalize font-bold border-0 shadow-lg py-2 px-[18px]`}
                        aria-pressed={type === filtersState.type}
                    >
                        {t(ProductTypesTranslationKeys[type])}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {showLoadingState ? (
                <SelectedProductsListSkeleton />
            ) : (
                <InfiniteScroll
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 32,
                        flex: 1,
                    }}
                    dataLength={filteredInventoryProducts.length}
                    next={nextPage}
                    hasMore={hasMorePages}
                    loader={
                        <CircularProgress
                            key={0}
                            sx={{ color: COLOR.paleSky, alignSelf: 'center' }}
                            data-test-id="InventoryPage-InfiniteScroll-LoadingSpinner"
                        />
                    }
                    scrollableTarget={scrollableElementRef ?? undefined}
                    hasChildren
                >
                    {paginatedInventoryProducts?.length ? (
                        <InventoryProductsList
                            inventoryProducts={paginatedInventoryProducts}
                            onRemoveButtonClick={handleRemoveButtonClick}
                        />
                    ) : (
                        <EmptyState
                            message={emptyStateMessages[filtersState.type]}
                        />
                    )}
                </InfiniteScroll>
            )}
            {productToRemove ? (
                <RemoveEntityAlert
                    dialogText={t('Inventory:dialog:remove_inventory', {
                        name: productToRemoveCompleteName,
                    })}
                    isOpen
                    onClose={closeRemoveProductAlert}
                    onRemove={removeProduct}
                    testID="RemoveProductAlert"
                />
            ) : null}
            {isAddProductModalVisible ? (
                <AddInventoryProductModal
                    onMainAction={handleAddProductsToInventory}
                    onCancel={handleAddProductModalClose}
                />
            ) : null}
        </Box>
    );
}

export default InventoryPage;
