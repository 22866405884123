import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { LOCALE } from 'shared/constants/localization/types';

import { getLocaleFromLocalStorage } from '@/services/localization';
export function useFormatDate() {
    const locale = getLocaleFromLocalStorage() || LOCALE.en_AU;
    const formatDate = useCallback(
        (
            date: string,
            format: 'date' | 'datetime' | 'time' | 'shortdatetime',
        ) => {
            switch (format) {
                case 'date':
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString({
                            year:
                                locale === LOCALE.en_US ? '2-digit' : 'numeric',
                            month: locale === LOCALE.en_US ? 'short' : 'long',
                            day: 'numeric',
                        });
                case 'datetime':
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString({
                            year: 'numeric',
                            month: 'long',
                            day:
                                locale === LOCALE.en_US ? '2-digit' : 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        });
                case 'time':
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString({
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: locale !== LOCALE.nl_NL,
                        });
                case 'shortdatetime':
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString({
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: locale !== LOCALE.nl_NL,
                        });
                default:
                    return DateTime.fromISO(date)
                        .setLocale(locale)
                        .toLocaleString();
            }
        },
        [locale],
    );

    return formatDate;
}
