import {
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicesAnalytics } from 'shared/analytics/invoices';
import Invoice from 'shared/db/services/Invoice';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { InvoiceModel } from 'shared/types/Invoice';
import { OrganisationAccountingsModel } from 'shared/types/OrganisationAccountings';

import { EmptyState } from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import RemoveEntityAlert from '@/components/RemoveEntityAlert';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { Snackbar } from '@/services/toastNotifications';
import { COLOR } from '@/theme/colors';

import InvoicesTableItem from './components/InvoicesTableItem';
import { Props } from './types';

const headerFontStyle: SxProps = {
    color: COLOR.paleSky,
    fontWeight: 700,
    fontSize: 13,
};

function InvoicesTable({
    emptyStateMessage,
    rows,
    selectable,
    selectItem,
    isItemChecked,
}: Props) {
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { t } = useTranslation();

    const [isEditInvoiceModalOpen, setIsEditInvoiceModalOpen] = useState(false);
    const [invoiceToEdit, setInvoiceToEdit] = useState<InvoiceModel | null>(
        null,
    );
    const [invoiceToDelete, setInvoiceToDelete] = useState<InvoiceModel | null>(
        null,
    );
    const [accountings, setAccountings] =
        useState<OrganisationAccountingsModel | null>(null);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const invoiceService = useMemo(
        () =>
            new Invoice({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService, database],
    );

    const fetchAccountings = useCallback(async () => {
        const accountingsService = new OrganisationAccountings({
            database,
            logDBAction: Logger.logRecordActivity,
        });

        const [accountingsData] = await accountingsService.get().fetch();

        setAccountings(accountingsData);
    }, [database]);

    useEffect(() => {
        fetchAccountings();
    }, [fetchAccountings]);

    const openEditInvoiceModal = useCallback(
        () => setIsEditInvoiceModalOpen(true),
        [],
    );
    const closeEditInvoiceModal = useCallback(
        () => setIsEditInvoiceModalOpen(false),
        [],
    );

    const handleInvoiceTableItemEdit = useCallback(
        (invoice: InvoiceModel) => {
            setInvoiceToEdit(invoice);
            openEditInvoiceModal();
        },
        [openEditInvoiceModal],
    );

    const [isRemoveInvoiceAlertOpen, setIsRemoveInvoiceAlertOpen] =
        useState(false);

    const openRemoveInvoiceAlert = useCallback(
        () => setIsRemoveInvoiceAlertOpen(true),
        [],
    );

    const closeRemoveInvoiceAlert = useCallback(
        () => setIsRemoveInvoiceAlertOpen(false),
        [],
    );

    const handleInvoiceTableDeleteItem = useCallback(
        (invoice: InvoiceModel) => {
            setInvoiceToDelete(invoice);
            openRemoveInvoiceAlert();
        },
        [openRemoveInvoiceAlert],
    );

    const handleRemoveInvoice = useCallback(async () => {
        try {
            if (!invoiceToDelete) {
                throw new Error();
            }

            const invoiceId = invoiceToDelete.id;

            await invoiceService.deleteByID(invoiceId);

            InvoicesAnalytics.logUserDeletedInvoice(FirebaseAnalytics.logEvent);

            Snackbar.showToastNotification({
                message: t('App:Messages:has_been_removed_successfully', {
                    entity: t('Entities:invoice'),
                }),
            });
        } catch {
            Snackbar.showToastNotification({
                message: t('App:Messages:something_went_wrong'),
                options: {
                    variant: 'error',
                },
            });
        }
    }, [t, invoiceToDelete, invoiceService]);

    const performInvoiceUpdate = useCallback(
        async (updatePromise: Promise<any>) => {
            try {
                await updatePromise;

                Snackbar.showToastNotification({
                    message: t('Invoice:updated'),
                });
            } catch {
                Snackbar.showToastNotification({
                    message: t('App:Messages:something_went_wrong'),
                    options: {
                        variant: 'error',
                    },
                });
            }
        },
        [t],
    );

    const handleSendAndAuthoriseInvoice = useCallback(
        async (invoice: InvoiceModel) => {
            const [contact] = await invoice.contacts.fetch();

            await performInvoiceUpdate(
                invoiceService.authoriseInvoice({
                    invoice,
                    contact,
                    hasAccountingProvider: !!accountings?.accountingProvider,
                }),
            );

            InvoicesAnalytics.logUserAuthorizedInvoice(
                FirebaseAnalytics.logEvent,
            );
        },
        [accountings?.accountingProvider, invoiceService, performInvoiceUpdate],
    );

    const handleSetInvoiceToPaid = useCallback(
        async (invoice: InvoiceModel) => {
            await performInvoiceUpdate(
                invoiceService.setInvoiceToPaid(invoice),
            );

            InvoicesAnalytics.logUserSetInvoiceToPaid(
                FirebaseAnalytics.logEvent,
            );
        },
        [performInvoiceUpdate, invoiceService],
    );

    const handleSendInvoice = useCallback(
        async (invoice: InvoiceModel) =>
            performInvoiceUpdate(
                invoiceService.sendInvoice(invoice, accountings),
            ),
        [performInvoiceUpdate, invoiceService, accountings],
    );

    return (
        <>
            <TableContainer>
                {!rows.length && emptyStateMessage ? (
                    <EmptyState
                        testID={'InvoicesTable-EmptyStateMessage'}
                        message={emptyStateMessage}
                    />
                ) : null}
                {rows.length ? (
                    <Table sx={{ minWidth: 650 }} aria-label="invoices table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography sx={headerFontStyle}>
                                        {t('InvoicesPage:columns:to')}
                                    </Typography>
                                </TableCell>

                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {`${t(
                                            'InvoicesPage:columns:total',
                                        )}(exGST)`}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t('InvoicesPage:columns:overdue')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t('InvoicesPage:columns:status')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t('InvoicesPage:columns:created_at')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography
                                        variant="caption"
                                        sx={headerFontStyle}
                                    >
                                        {t('InvoicesPage:columns:updated_at')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody data-test-id={'InvoicesPage-TableBody'}>
                            {rows.map((row) => (
                                <InvoicesTableItem
                                    onEdit={handleInvoiceTableItemEdit}
                                    onDelete={handleInvoiceTableDeleteItem}
                                    onSendAndAuthorise={
                                        handleSendAndAuthoriseInvoice
                                    }
                                    onSend={handleSendInvoice}
                                    onSetToPaid={handleSetInvoiceToPaid}
                                    invoice={row}
                                    key={row.id}
                                    selectable={selectable}
                                    isSelected={isItemChecked(row)}
                                    onSelect={() => selectItem(row)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                ) : null}
            </TableContainer>
            {isEditInvoiceModalOpen && invoiceToEdit ? (
                <AddEditInvoiceModal
                    close={closeEditInvoiceModal}
                    onRemove={() => null}
                    invoice={invoiceToEdit}
                    isEditMode
                />
            ) : null}
            <RemoveEntityAlert
                dialogText={t('Invoice:delete_invoice_confirmation')}
                isOpen={isRemoveInvoiceAlertOpen}
                onClose={closeRemoveInvoiceAlert}
                onRemove={handleRemoveInvoice}
                testID="RemoveInvoiceAlert"
            />
        </>
    );
}

export default memo(InvoicesTable);
