import { Collection, Database, Q } from '@nozbe/watermelondb';
import {
    RsvpOnboardingModel,
    RsvpOnboardingPayload,
} from '../../types/RsvpOnboarding';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class RsvpOnboarding {
    private database: Database;
    private collection: Collection<RsvpOnboardingModel>;
    private table = 'onboardings';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getByUserId(userId: string) {
        return this.collection.query(Q.where('user_id', userId));
    }

    getAll() {
        return this.collection.query();
    }

    async add(payload: RsvpOnboardingPayload) {
        return this.database.write(async () => {
            const createdOnboarding = await this.collection.create(
                (onboarding) => {
                    onboarding.isAppointmentOnboarded =
                        payload.isAppointmentOnboarded ?? false;
                    onboarding.isInvitationOnboarded =
                        payload.isInvitationOnboarded ?? false;
                    onboarding.isBannerOnboarded =
                        payload.isBannerOnboarded ?? false;
                    onboarding.isDeclinedAppointmentsOnboarded =
                        payload.isDeclinedAppointmentsOnboarded ?? false;
                    onboarding.userId = payload.userId;
                },
            );

            this.options.logDBAction({
                message: 'Create RSVP onboarding',
                modelName: this.table,
                payload: createdOnboarding,
            });

            return createdOnboarding;
        });
    }

    async update(payload: RsvpOnboardingPayload, rsvpOnboardingId) {
        const rsvpOnboarding = await this.collection.find(rsvpOnboardingId);

        return this.database.write(async () => {
            const updatedRsvpOnboarding = await rsvpOnboarding.update(
                (rsvpOnboardingElement) => {
                    rsvpOnboardingElement.isAppointmentOnboarded =
                        payload.isAppointmentOnboarded ??
                        rsvpOnboardingElement.isAppointmentOnboarded;
                    rsvpOnboardingElement.isInvitationOnboarded =
                        payload.isInvitationOnboarded ??
                        rsvpOnboardingElement.isInvitationOnboarded;
                    rsvpOnboardingElement.isBannerOnboarded =
                        payload.isBannerOnboarded ??
                        rsvpOnboardingElement.isBannerOnboarded;
                    rsvpOnboardingElement.isDeclinedAppointmentsOnboarded =
                        payload.isDeclinedAppointmentsOnboarded ??
                        rsvpOnboardingElement.isDeclinedAppointmentsOnboarded;
                },
            );

            this.options.logDBAction({
                message: 'Update RSVP onboarding',
                modelName: this.table,
                payload: updatedRsvpOnboarding,
            });

            return updatedRsvpOnboarding;
        });
    }
}

export default RsvpOnboarding;
