import { Alert, AlertTitle, Box, Container, Snackbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    EnvInfoRibbon,
    Navbar,
    Sidebar,
    FreshchatScriptWrapper,
    TeamSwitcher,
    Spotlight,
    RSVPBannerOverlay,
} from '@/components';
import AppointmentsBanner from '@/components/AppointmentsBanner';
import { APP_CONFIG } from '@/constants/appConfig';
import { useAuthContext } from '@/context/AuthContext';
import { useFeatureFlagsContext } from '@/context/FeatureFlagsContext';
import { useFreshchatContext } from '@/context/FreshchatContext';
import { usePageScrollableElementRef } from '@/context/PageScrollableElementRefContext';
import { useRSVPOverlayContext } from '@/context/RSVPOverlayContext';
import { getSecuredRoutes } from '@/helpers/router';
import { useRouteMatch } from '@/hooks';
import { ROUTE, routes } from '@/router/routes';

const securedRoutes = getSecuredRoutes(routes);

const pageWrapperGridStyles = {
    display: 'grid',
    gridTemplateRows: '63px 1fr',
    gridTemplateColumns: '280px 1fr',
    height: '100vh',
    overflow: 'hidden',
};

const navBarContainerStyles = {
    gridColumn: '1 / 3',
    gridRow: '1 / 2',
};
const sideBarContainerStyles = {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
};
const mainContainerStyles = {
    gridColumn: '2 / 3',
    gridRow: '2 / 3',
    overflowY: 'auto',
    px: 7,
    width: '100%',
    height: '100%',
};

function renderEnvironmentRibbon() {
    if (
        APP_CONFIG.environmentName &&
        APP_CONFIG.environmentName !== 'production'
    ) {
        return <EnvInfoRibbon envName={APP_CONFIG.environmentName} />;
    }

    return null;
}

function PageWrapper({ children }) {
    const { t } = useTranslation();
    const { isUserAuthenticated } = useAuthContext();
    const { showRSVPBannerOverlay } = useRSVPOverlayContext();

    const showSidebar = useRouteMatch(securedRoutes);
    const resetContainerStyles = useRouteMatch([ROUTE.teams]);

    const showAppointmentsBanner = useRouteMatch([
        ROUTE.dashboard,
        ROUTE.contact,
        ROUTE.schedule,
    ]);

    const isExternalAppointmentPage = useRouteMatch([
        ROUTE.externalAppointment,
    ]);

    const isDashboardOrSchedulesPage = useRouteMatch([
        ROUTE.dashboard,
        ROUTE.schedule,
    ]);

    const [displayToast, setDisplayToast] = useState(true);

    const {
        maintenanceConfig: { from, to, isInMaintenance, showToast },
    } = useFeatureFlagsContext();

    const { isFreshchatUserReady, enableFreshchatUnsecured } =
        useFreshchatContext();

    const { setScrollableElementRef } = usePageScrollableElementRef();

    const closeToast = useCallback(() => setDisplayToast(false), []);

    useEffect(() => {
        setTimeout(closeToast, 60000);
    }, [closeToast]);

    const showOverlay = showRSVPBannerOverlay && isDashboardOrSchedulesPage;

    if (isUserAuthenticated && showSidebar) {
        return (
            <>
                {isFreshchatUserReady ? (
                    <FreshchatScriptWrapper chat="true" />
                ) : null}
                {showOverlay ? <RSVPBannerOverlay /> : null}
                <Box ref={(ref) => ref} sx={pageWrapperGridStyles}>
                    <TeamSwitcher />
                    <Box sx={navBarContainerStyles}>
                        <Navbar />
                    </Box>
                    <Box sx={sideBarContainerStyles}>
                        <Sidebar />
                    </Box>
                    <Box
                        id="app-content"
                        ref={setScrollableElementRef}
                        className="relative"
                        sx={
                            resetContainerStyles
                                ? { overflowY: 'auto' }
                                : mainContainerStyles
                        }
                    >
                        {showAppointmentsBanner ? (
                            <Box>
                                <AppointmentsBanner animate={showOverlay} />
                                {showOverlay ? (
                                    <Spotlight
                                        width="100%"
                                        height="40px"
                                        sx={{
                                            px: 6,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            zIndex: 50,
                                        }}
                                    />
                                ) : null}
                            </Box>
                        ) : null}
                        {showAppointmentsBanner ? <AppointmentsBanner /> : null}
                        {from && to && !isInMaintenance && showToast ? (
                            <Snackbar
                                open={displayToast}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Alert severity="info" onClose={closeToast}>
                                    <AlertTitle>
                                        {t('App:Maintenance:Toast:title')}
                                    </AlertTitle>
                                    {t('App:Maintenance:Toast:message', {
                                        from,
                                        to,
                                    })}
                                </Alert>
                            </Snackbar>
                        ) : null}
                        <Container
                            disableGutters={resetContainerStyles}
                            maxWidth={resetContainerStyles ? false : 'lg'}
                            sx={{
                                height: 'max-content',
                                py: resetContainerStyles ? 0 : 8,
                                width: '100%',
                            }}
                        >
                            {children}
                        </Container>
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <>
            {enableFreshchatUnsecured && !isExternalAppointmentPage ? (
                <FreshchatScriptWrapper chat="true" />
            ) : null}
            {children}
            {renderEnvironmentRibbon()}
        </>
    );
}

export default PageWrapper;
