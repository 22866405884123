import { ProcedureModel } from '../types/Procedures';
import {
    CATEGORIES,
    CATEGORY_DICTIONARY,
    CATEGORY_HUMANIZED,
} from '../constants/procedure/CATEGORIES';
import { EntryProcedureToAdd } from '../types/Entries';
import { EntryProcedureModel } from '../types/EntryProcedure';

export type ProceduresGroup<
    T extends ProcedureModel | EntryProcedureToAdd = ProcedureModel,
> = {
    title: CATEGORY_HUMANIZED;
    procedures: T[];
};

export function getGroupedProcedures<
    T extends ProcedureModel | EntryProcedureToAdd = ProcedureModel,
>(procedures: T[]): ProceduresGroup<T>[] {
    const groupedProcedures: {
        [key: string]: T[];
    } = {};
    CATEGORIES.forEach((category) => (groupedProcedures[category] = []));

    procedures.forEach((procedure) => {
        const procedureCategory =
            CATEGORY_HUMANIZED[
                procedure?.category ?? CATEGORY_DICTIONARY.Other
            ];

        groupedProcedures[procedureCategory].push(procedure);
    });

    return Object.keys(groupedProcedures).map((category) => ({
        title: category as CATEGORY_HUMANIZED,
        procedures: groupedProcedures[category],
    }));
}

export function mapWatermelonDBProceduresObjectsToRawObjects(
    entryProcedure: EntryProcedureModel,
): EntryProcedureToAdd {
    return {
        name: entryProcedure.name,
        price: entryProcedure.price,
        procedureId: entryProcedure.procedureId,
        quantity: entryProcedure.quantity,
    };
}
