import { Add, CancelRounded, TuneRounded } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { of } from 'rxjs';
import { EntriesAnalytics } from 'shared/analytics/entries';
import { ENTRIES_FILTER } from 'shared/constants/entries/filters';
import { PAGE_LIMIT } from 'shared/constants/pagination';
import Database from 'shared/db/services/Database.web';
import EntriesService from 'shared/db/services/Entry';
import { EntryModel } from 'shared/types/Entries';
import {
    EntriesFiltersObject,
    EntryQueryOptions,
} from 'shared/types/entriesFilters';
import { FILTER_TYPE } from 'shared/types/filter';
import { InvoiceModel } from 'shared/types/Invoice';

import {
    Button,
    Pagination,
    SearchInput,
    TableSkeleton,
    AddEditEntryModal,
} from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import { APP_CONTENT_ID } from '@/constants/documentIds';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useEntriesFiltersContext } from '@/context/EntriesFiltersContext';
import { useImagesContext } from '@/context/ImagesContext';
import { calculatePaginatedRows } from '@/helpers/pagination';
import { withSyncContext } from '@/hoc';
import {
    useDatabase,
    useDebouncedValue,
    useScrollToDocumentTopOnValueChange,
} from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { t as translationsConfig } from '@/services/translations/config';
import { COLOR } from '@/theme/colors';
import { RouterLocation } from '@/types/router';
import { getRoutePath } from '@/utils/router';

import { EntriesTable, FiltersPanel } from './components';
import { Props } from './types';

const paginationStyles: SxProps<Theme> = {
    paddingBlock: 3,
    mr: 3,
    display: 'flex',
    justifyContent: 'flex-end',
};

function EntriesPage({ entries, entriesRecordsCount }: Props) {
    const { isInitialSyncInProgress } = useDBSyncContext();
    const {
        filtersState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        searchState,
        setFiltersState,
        setIsFiltersPanelVisible,
        setSearchState,
        setTempDateRangeFilterState,
        tempDateRangeFilterState,
    } = useEntriesFiltersContext();

    const location = useLocation();
    const locationState =
        location.state as RouterLocation.EntriesPageState | null;

    const { getDatabase } = useDatabase();
    const { t } = useTranslation();
    const { ImagesService, isImagesSyncInProgress } = useImagesContext();
    const navigate = useNavigate();

    const [filteredEntries, setFilteredEntries] = useState<EntryModel[]>([]);
    const [isEntriesDataLoading, setIsEntriesDataLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState<number>(
        entriesRecordsCount ? entriesRecordsCount : 0,
    );
    const [isAddEntryModalOpen, setIsAddEntryModalOpen] = useState(false);
    const [entryToEdit, setEntryToEdit] = useState<EntryModel | null>(null);
    const [isEditEntryModalOpen, setIsEditEntryModalOpen] =
        useState<boolean>(false);
    const [entryToAddToInvoice, setEntryToAddToInvoice] =
        useState<EntryModel | null>(null);
    const [isAddInvoiceModalOpen, setIsAddInvoiceModalOpen] =
        useState<boolean>(false);

    const applyFiltersFromLocationState = useCallback(() => {
        if (locationState?.filterToApply) {
            const { filterToApply } = locationState;

            resetFilters();
            setFiltersState((prev) => ({
                ...prev,
                [filterToApply.key]: [filterToApply.value],
            }));
        }
    }, [locationState, resetFilters, setFiltersState]);

    const openEditEntryModal = useCallback(
        () => setIsEditEntryModalOpen(true),
        [],
    );
    const closeEditEntryModal = useCallback(
        () => setIsEditEntryModalOpen(false),
        [],
    );

    const openAddInvoiceModal = useCallback(
        () => setIsAddInvoiceModalOpen(true),
        [],
    );
    const closeAddInvoiceModal = useCallback(
        () => setIsAddInvoiceModalOpen(false),
        [],
    );

    const onSaveInvoice = useCallback(
        (invoice: InvoiceModel) => {
            const route = getRoutePath(ROUTE.invoice, {
                id: invoice.id,
            });
            navigate(route);
        },
        [navigate],
    );

    useEffect(() => {
        applyFiltersFromLocationState();

        return () => {
            // Reset filters on unmount if user was navigated to the page
            // from the shortcut with filter applied
            if (locationState?.filterToApply) {
                resetFilters();
            }
        };
    }, [applyFiltersFromLocationState, locationState, resetFilters]);

    useEffect(() => {
        if (!isImagesSyncInProgress && !isEditEntryModalOpen) {
            setEntryToEdit(null);
        }
    }, [isEditEntryModalOpen, isImagesSyncInProgress]);

    const handleEditEntry = useCallback(
        (entry: EntryModel) => {
            setEntryToEdit(entry);
            openEditEntryModal();
        },
        [openEditEntryModal],
    );

    const handleAddInvoiceToEntry = useCallback(
        (entry: EntryModel) => {
            setEntryToAddToInvoice(entry);
            openAddInvoiceModal();
        },
        [openAddInvoiceModal],
    );

    useScrollToDocumentTopOnValueChange(APP_CONTENT_ID, page);

    const toggleFiltersPanel = useCallback(() => {
        setIsFiltersPanelVisible((prev) => !prev);
    }, [setIsFiltersPanelVisible]);

    const handlePageChange = useCallback(
        (_, page: number) => setPage(page),
        [],
    );

    const database = useMemo(() => {
        return getDatabase();
    }, [getDatabase]);

    const handleFiltersChange = useCallback(
        (filterType: ENTRIES_FILTER) => (value: string[]) => {
            setFiltersState((prev) => ({ ...prev, [filterType]: value }));
        },
        [setFiltersState],
    );

    const handleSearchInputChange = useCallback(
        (value: string) => {
            setSearchState(value);
        },
        [setSearchState],
    );

    const clearSearchText = useCallback(() => {
        setSearchState('');
    }, [setSearchState]);

    const openAddEntryModal = useCallback(
        () => setIsAddEntryModalOpen(true),
        [],
    );

    const closeAddEntryModal = useCallback(
        () => setIsAddEntryModalOpen(false),
        [],
    );

    const fetchEntries = useCallback(
        async (searchText: string, filters: EntriesFiltersObject) => {
            setIsEntriesDataLoading(true);

            try {
                const entriesService = new EntriesService({
                    database,
                    imageService: ImagesService,
                    logDBAction: Logger.logRecordActivity,
                });

                const options: EntryQueryOptions = {
                    searchText,
                    filters,
                    filterType: FILTER_TYPE.LOKI,
                };

                const filteredEntriesCount =
                    await entriesService.getFilteredEntriesCount(options);

                setRowsCount(filteredEntriesCount);

                let paginatedEntries: EntryModel[] = [];

                if (filteredEntriesCount > 0) {
                    const entries = await entriesService.getFilteredEntries(
                        options,
                    );

                    paginatedEntries = calculatePaginatedRows(page, entries);
                }

                setFilteredEntries(paginatedEntries);
            } finally {
                setIsEntriesDataLoading(false);
            }
        },
        [ImagesService, database, page],
    );

    const filtersStateDebounced = useDebouncedValue(filtersState, 300);
    const isAnyFilterActiveDebounced = useDebouncedValue(
        isAnyFilterActive,
        300,
    );
    const searchTextDebounced = useDebouncedValue(searchState);

    useEffect(() => setPage(1), [filtersStateDebounced, searchTextDebounced]);

    useEffect(() => {
        if (searchTextDebounced) {
            EntriesAnalytics.logUserUsedEntriesSearchBar(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [searchTextDebounced]);

    useEffect(() => {
        fetchEntries(searchTextDebounced, filtersStateDebounced);
    }, [fetchEntries, filtersStateDebounced, searchTextDebounced]);

    const handleNewEntry = useCallback(
        (entry: EntryModel) => {
            const route = getRoutePath(ROUTE.entry, { id: entry.id });
            navigate(route);
        },
        [navigate],
    );

    const refetchEntries = useCallback(() => {
        fetchEntries(searchTextDebounced, filtersStateDebounced);
    }, [fetchEntries, filtersStateDebounced, searchTextDebounced]);

    const showLoadingState =
        !entries?.length && (isInitialSyncInProgress || isEntriesDataLoading);

    const emptyStateMessage =
        !filteredEntries.length && entries && entries.length > 0
            ? t('EntriesList:no_entry_found')
            : t('EntriesList:no_entries_have_been_added_yet');

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4,
                }}
            >
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <SearchInput
                        onChange={handleSearchInputChange}
                        onClear={clearSearchText}
                        testId="EntriesPage-SearchInput"
                        value={searchState}
                    />
                    <Button
                        caption={t('Actions:filter')}
                        color={isAnyFilterActive ? 'primary' : 'inherit'}
                        onClick={toggleFiltersPanel}
                        startIcon={<TuneRounded />}
                        sx={{
                            backgroundColor: isAnyFilterActive
                                ? COLOR.deepCerulean08
                                : undefined,
                            ml: 3,
                        }}
                        testID="EntriesPage-FilterButton"
                    />
                    {isAnyFilterActive ? (
                        <Button
                            caption={t('Actions:clearFilters')}
                            onClick={resetFilters}
                            startIcon={
                                <CancelRounded htmlColor={COLOR.paleSky} />
                            }
                            sx={{
                                color: COLOR.paleSky,
                                fontWeight: 400,
                                ml: 3,
                            }}
                            testID="EntriesPage-ClearFiltersButton"
                        />
                    ) : null}
                </Box>
                <Button
                    caption={translationsConfig('EntriesList:button:add_entry')}
                    onClick={openAddEntryModal}
                    startIcon={<Add />}
                    testID="EntriesPage-AddEntryButton"
                />
            </Box>
            {isFiltersPanelVisible ? (
                <Box sx={{ mb: 4 }}>
                    <FiltersPanel
                        filtersState={filtersState}
                        onFiltersChange={handleFiltersChange}
                        setTempDateRangeFilterState={
                            setTempDateRangeFilterState
                        }
                        tempDateRangeFilterState={tempDateRangeFilterState}
                    />
                </Box>
            ) : null}
            <Box sx={{ mr: 3 }}>
                {showLoadingState ? (
                    <TableSkeleton data-test-id="EntriesPage-TableSkeleton" />
                ) : (
                    <EntriesTable
                        emptyStateMessage={emptyStateMessage}
                        entries={
                            searchTextDebounced ||
                            isAnyFilterActiveDebounced ||
                            page > 1
                                ? filteredEntries
                                : entries || []
                        }
                        loadingEntry={entryToEdit}
                        onEditItem={handleEditEntry}
                        onAddInvoice={handleAddInvoiceToEntry}
                    />
                )}
            </Box>
            {rowsCount > 0 && (
                <Box sx={paginationStyles}>
                    <Pagination
                        testID="HorsesPage-Pagination"
                        page={page}
                        recordsCount={rowsCount}
                        onChange={handlePageChange}
                    />
                </Box>
            )}
            {isAddEntryModalOpen ? (
                <AddEditEntryModal
                    close={closeAddEntryModal}
                    onSave={handleNewEntry}
                />
            ) : isEditEntryModalOpen && entryToEdit ? (
                <AddEditEntryModal
                    close={closeEditEntryModal}
                    isEditMode
                    entry={entryToEdit}
                    onSave={refetchEntries}
                />
            ) : null}
            {isAddInvoiceModalOpen && entryToAddToInvoice ? (
                <AddEditInvoiceModal
                    close={closeAddInvoiceModal}
                    entry={entryToAddToInvoice}
                    onSave={onSaveInvoice}
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();

        const queries = [Q.sortBy('logged_time', 'desc')];

        const entriesRecordsCount = isInitialSyncInProgress
            ? of()
            : database.collections
                  .get('entries')
                  .query(...queries)
                  .observeCount();

        const entries = isInitialSyncInProgress
            ? of([])
            : database.collections
                  .get('entries')
                  .query(...queries, Q.skip(0), Q.take(PAGE_LIMIT));

        return {
            entries,
            entriesRecordsCount,
        };
    },
);

export default withSyncContext<Props>(enhance(EntriesPage));
