import { Add, CancelRounded, TuneRounded } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { Box, SxProps, Theme } from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { of } from 'rxjs/internal/observable/of';
import { InvoicesAnalytics } from 'shared/analytics/invoices';
import { INVOICES_FILTER } from 'shared/constants/invoices/filters';
import Database from 'shared/db/services/Database.web';
import Invoice from 'shared/db/services/Invoice';
import OrganisationAccountings from 'shared/db/services/OrganisationAccountings';
import { FILTER_TYPE } from 'shared/types/filter';
import { InvoiceModel } from 'shared/types/Invoice';
import { InvoicesFiltersObject } from 'shared/types/invoicesFilters';
import { EXPORT_INVOICE_VISIBILITY } from 'shared/types/Invoicing';

import {
    Button,
    Pagination,
    SearchInput,
    SelectableController,
    TableSkeleton,
    TooltipSyncButton,
} from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import { APP_CONTENT_ID } from '@/constants/documentIds';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useImagesContext } from '@/context/ImagesContext';
import { useInvoicesFiltersContext } from '@/context/InvoicesFiltersContext';
import { calculatePaginatedRows, PAGE_LIMIT } from '@/helpers/pagination';
import { withSyncContext } from '@/hoc';
import {
    useDebouncedValue,
    useScrollToDocumentTopOnValueChange,
    useUnmount,
} from '@/hooks';
import { ROUTE } from '@/router/routes';
import { FirebaseAnalytics } from '@/services/firebase/analytics';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';
import { RouterLocation } from '@/types/router';
import { getRoutePath } from '@/utils/router';

import { ExportInvoicesModal, FiltersPanel, InvoicesTable } from './components';
import { Props } from './types';

const pageStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const tableStyles: SxProps<Theme> = {
    mr: 3,
    flexGrow: 1,
};

function InvoicesPage({
    invoices,
    invoicesCount = 0,
    organisationAccountingsData,
}: Props) {
    const {
        filtersState,
        searchState,
        setSearchState,
        selectedRows,
        exportPanelVisibility,
        onAllSelected,
        onCheckboxClick,
        isCheckboxTicked,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        setFiltersState,
        setIsFiltersPanelVisible,
        setExportPanelVisibility,
    } = useInvoicesFiltersContext();

    const location = useLocation();
    const locationState =
        location.state as RouterLocation.InvoicesPageState | null;

    const { isInitialSyncInProgress, isSyncInProgress } = useDBSyncContext();

    const { ImagesService } = useImagesContext();

    const navigate = useNavigate();

    const [isInvoicesDataLoading, setIsInvoicesDataLoading] = useState(false);
    // Filtered but paginated invoices
    const [filteredInvoices, setFilteredInvoices] = useState<InvoiceModel[]>(
        [],
    );

    const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);

    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState<number>(invoicesCount);

    const navigateToCreatedInvoice = useCallback(
        (invoice: InvoiceModel) => {
            const route = getRoutePath(ROUTE.invoice, { id: invoice.id });
            navigate(route);
        },
        [navigate],
    );

    const applyFiltersFromLocationState = useCallback(() => {
        if (locationState?.filterToApply) {
            const { filterToApply } = locationState;

            resetFilters();
            setFiltersState((prev) => ({
                ...prev,
                [filterToApply.key]: [filterToApply.value],
            }));
        }
    }, [locationState, resetFilters, setFiltersState]);

    const handlePageChange = useCallback(
        (_, page: number) => setPage(page),
        [],
    );

    useScrollToDocumentTopOnValueChange(APP_CONTENT_ID, page);

    const userHasProvider = useMemo(
        () =>
            !!(
                organisationAccountingsData &&
                organisationAccountingsData[0] &&
                !!organisationAccountingsData[0].accountingProvider
            ),
        [organisationAccountingsData],
    );

    const invoiceService = useMemo(
        () =>
            new Invoice({
                database: Database.getDatabase(),
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            }),
        [ImagesService],
    );

    const openAddInvoiceModal = useCallback(
        () => setShowAddInvoiceModal(true),
        [],
    );

    const closeAddInvoiceModal = useCallback(
        () => setShowAddInvoiceModal(false),
        [],
    );

    const hiddenExportVisiblitiy = useCallback(
        () => setExportPanelVisibility(EXPORT_INVOICE_VISIBILITY.HIDDEN),
        [setExportPanelVisibility],
    );

    const selectingExportVisibility = useCallback(
        () =>
            setExportPanelVisibility(EXPORT_INVOICE_VISIBILITY.SELECTING_PHASE),
        [setExportPanelVisibility],
    );

    const exportingExportVisibility = useCallback(
        () =>
            setExportPanelVisibility(EXPORT_INVOICE_VISIBILITY.EXPORTING_PHASE),
        [setExportPanelVisibility],
    );

    const toggleFiltersPanel = useCallback(() => {
        setIsFiltersPanelVisible((prev) => !prev);
    }, [setIsFiltersPanelVisible]);

    const fetchInvoices = useCallback(
        async (search: string, filters: InvoicesFiltersObject) => {
            setIsInvoicesDataLoading(true);

            try {
                const filteredInvoicesCount =
                    await invoiceService.getFilteredInvoicesCount({
                        searchText: search,
                        filters,
                        filterType: FILTER_TYPE.LOKI,
                        userHasProvider,
                    });

                setRowsCount(filteredInvoicesCount);

                let paginatedInvoices: InvoiceModel[] = [];
                if (filteredInvoicesCount > 0) {
                    const invoices = await invoiceService.getFilteredInvoices({
                        searchText: search,
                        filters,
                        filterType: FILTER_TYPE.LOKI,
                        userHasProvider,
                    });

                    paginatedInvoices = calculatePaginatedRows(page, invoices);
                }
                setFilteredInvoices(paginatedInvoices);
            } finally {
                setIsInvoicesDataLoading(false);
            }
        },
        [invoiceService, page, userHasProvider],
    );

    const handleFiltersChange = useCallback(
        (filterType: INVOICES_FILTER) => (value: string[]) => {
            setFiltersState((prev) => ({ ...prev, [filterType]: value }));
        },
        [setFiltersState],
    );

    const handleSearchInputChange = useCallback(
        (value: string) => setSearchState(value),
        [setSearchState],
    );

    const clearSearchText = useCallback(
        () => setSearchState(''),
        [setSearchState],
    );

    const filtersStateDebounced = useDebouncedValue(filtersState, 300);
    const isAnyFilterActiveDebounced = useDebouncedValue(
        isAnyFilterActive,
        300,
    );
    const searchTextDebounced = useDebouncedValue(searchState);

    const presentedInvoices = useMemo(
        () =>
            searchTextDebounced || isAnyFilterActiveDebounced || page > 1
                ? filteredInvoices
                : invoices ?? [],
        [
            filteredInvoices,
            isAnyFilterActiveDebounced,
            page,
            searchTextDebounced,
            invoices,
        ],
    );

    useEffect(() => {
        applyFiltersFromLocationState();

        return () => {
            // Reset filters on unmount if user was navigated to the page
            // from the shortcut with filter applied
            if (locationState?.filterToApply) {
                resetFilters();
            }
        };
    }, [applyFiltersFromLocationState, locationState, resetFilters]);

    useEffect(() => setPage(1), [filtersStateDebounced, searchTextDebounced]);

    useEffect(() => {
        if (searchTextDebounced) {
            InvoicesAnalytics.logUserUsedInvoicesSearchBar(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [searchTextDebounced]);

    useEffect(() => {
        if (
            filtersStateDebounced.contacts.length ||
            filtersStateDebounced.status.length ||
            filtersStateDebounced.date_range.isActive ||
            filtersStateDebounced.show_overdue
        ) {
            InvoicesAnalytics.logUserFilteredInvoices(
                FirebaseAnalytics.logEvent,
            );
        }
    }, [filtersStateDebounced]);

    useEffect(() => {
        if (!isInitialSyncInProgress) {
            fetchInvoices(searchTextDebounced, filtersStateDebounced);
        }
    }, [
        fetchInvoices,
        isInitialSyncInProgress,
        searchTextDebounced,
        filtersStateDebounced,
    ]);

    useUnmount(() => {
        if (!isAnyFilterActive && isFiltersPanelVisible) {
            setIsFiltersPanelVisible(false);
        }
    });

    const refetchInvoices = useCallback(() => {
        fetchInvoices(searchTextDebounced, filtersStateDebounced);
    }, [fetchInvoices, filtersStateDebounced, searchTextDebounced]);

    const selectedInvoicesIds = useMemo(() => {
        return selectedRows.map((invoice) => invoice.id);
    }, [selectedRows]);

    const emptyStateMessage =
        fetchInvoices.length === 0 && invoices && invoices.length > 0
            ? t('InvoicesPage:no_invoice_found_blankslate')
            : t('InvoicesPage:no_invoices_has_been_added');

    const showLoadingState =
        (!invoices?.length && isInvoicesDataLoading) || isInitialSyncInProgress;

    return (
        <Box sx={pageStyles}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 4,
                    }}
                >
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <SearchInput
                            onChange={handleSearchInputChange}
                            onClear={clearSearchText}
                            testId="InvoicesPage-SearchInput"
                            value={searchState}
                        />
                        <Button
                            caption={t('Actions:filter')}
                            color={isAnyFilterActive ? 'primary' : 'inherit'}
                            onClick={toggleFiltersPanel}
                            startIcon={<TuneRounded />}
                            sx={{
                                backgroundColor: isAnyFilterActive
                                    ? COLOR.deepCerulean08
                                    : undefined,
                                ml: 3,
                            }}
                            data-test-id="InvoicesPage-FilterButton"
                        />
                        {isAnyFilterActive ? (
                            <Button
                                caption={t('Actions:clearFilters')}
                                onClick={resetFilters}
                                startIcon={
                                    <CancelRounded htmlColor={COLOR.paleSky} />
                                }
                                sx={{
                                    color: COLOR.paleSky,
                                    fontWeight: 400,
                                    ml: 3,
                                }}
                                data-test-id="InvoicesPage-ClearFiltersButton"
                            />
                        ) : null}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <TooltipSyncButton
                            tooltip={
                                isSyncInProgress
                                    ? t('AddEditModalHeader:button_tooltip', {
                                          defaultValue:
                                              'Please wait for the synchronization to complete',
                                      })
                                    : t(
                                          'SelectableController:labels:export_hover',
                                      )
                            }
                            showTooltip={
                                isSyncInProgress ||
                                exportPanelVisibility !==
                                    EXPORT_INVOICE_VISIBILITY.SELECTING_PHASE
                            }
                            tooltipPlacement="bottom"
                            disabled={
                                isSyncInProgress ||
                                exportPanelVisibility ===
                                    EXPORT_INVOICE_VISIBILITY.SELECTING_PHASE
                            }
                            onClick={selectingExportVisibility}
                            testID="InvoicesPage-ExportButton"
                            sx={{
                                mr: 3,
                                fontWeight: 700,
                                height: 37,
                                color: COLOR.black,
                                borderColor: COLOR.lightGray,
                                borderWidth: '1px',
                                borderRadius: '8px',
                                '&:hover': {
                                    borderColor: COLOR.lightGray,
                                    borderWidth: '1px',
                                },
                            }}
                            startIcon={<ShareIcon />}
                            variant="outlined"
                        >
                            {t('Invoicing:export')}
                        </TooltipSyncButton>
                        <Button
                            caption={t('InvoicesPage:buttons:add')}
                            data-test-id="InvoicesPage-AddInvoiceButton"
                            onClick={openAddInvoiceModal}
                            startIcon={<Add />}
                        />
                    </Box>
                </Box>
                {isFiltersPanelVisible ? (
                    <Box sx={{ mb: 4 }}>
                        <FiltersPanel
                            filtersState={filtersState}
                            onFiltersChange={handleFiltersChange}
                        />
                    </Box>
                ) : null}
            </Box>
            {exportPanelVisibility ===
            EXPORT_INVOICE_VISIBILITY.SELECTING_PHASE ? (
                <Box>
                    <SelectableController
                        items={invoices ?? []}
                        selectedItems={selectedRows}
                        actionLabel={t(
                            'SelectableController:actions:invoice_export',
                        )}
                        onSelectAll={onAllSelected}
                        close={hiddenExportVisiblitiy}
                        onActionPerformed={exportingExportVisibility}
                    />
                </Box>
            ) : null}
            <Box sx={tableStyles}>
                {showLoadingState ? (
                    <TableSkeleton data-test-id="InvoicesPage-TableSkeleton" />
                ) : (
                    <InvoicesTable
                        refetchInvoices={refetchInvoices}
                        emptyStateMessage={emptyStateMessage}
                        rows={presentedInvoices}
                        selectable={
                            exportPanelVisibility ===
                            EXPORT_INVOICE_VISIBILITY.SELECTING_PHASE
                        }
                        selectItem={onCheckboxClick}
                        isItemChecked={isCheckboxTicked}
                    />
                )}
            </Box>
            {rowsCount > 0 && (
                <Pagination
                    testID="InvoicesPage-Pagination"
                    page={page}
                    recordsCount={rowsCount}
                    onChange={handlePageChange}
                />
            )}
            {showAddInvoiceModal ? (
                <AddEditInvoiceModal
                    onSave={navigateToCreatedInvoice}
                    close={closeAddInvoiceModal}
                />
            ) : null}
            {exportPanelVisibility ===
            EXPORT_INVOICE_VISIBILITY.EXPORTING_PHASE ? (
                <ExportInvoicesModal
                    close={hiddenExportVisiblitiy}
                    invoicesIds={selectedInvoicesIds}
                    showInfoAboutFilter={
                        isAnyFilterActive || !!searchState.length
                    }
                />
            ) : null}
        </Box>
    );
}

const enhance = withObservables<Props, unknown>(
    ['isInitialSyncInProgress'],
    ({ isInitialSyncInProgress }) => {
        const database = Database.getDatabase();
        const accountingsService = new OrganisationAccountings({
            database,
            logDBAction: Logger.logRecordActivity,
        });

        const invoiceRecords = database.collections
            .get<InvoiceModel>('invoices')
            .query()
            .observeCount();

        const invoices = database.collections
            .get<InvoiceModel>('invoices')
            .query(
                Q.sortBy('created_at', 'desc'),
                Q.skip(0),
                Q.take(PAGE_LIMIT),
            );

        return {
            invoices: isInitialSyncInProgress ? of() : invoices,
            invoicesCount: isInitialSyncInProgress ? of(0) : invoiceRecords,
            organisationAccountingsData: isInitialSyncInProgress
                ? of()
                : accountingsService.get(),
        };
    },
);

export default withSyncContext<Props>(enhance(InvoicesPage));
