import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE } from 'shared/constants/localization/types';

import { getLocaleFromLocalStorage } from '@/services/localization';
import { COLOR } from '@/theme/colors';
import { formatEnglishOrdinals } from '@/utils/date';

import { Props } from './types';

function getDatePartsFromLocale(dateISO: string): [string, string, string] {
    const locale = getLocaleFromLocalStorage() || LOCALE.en_AU;
    const parsedDate = DateTime.fromISO(dateISO).setLocale(locale);

    if (locale === LOCALE.en_US) {
        return [
            parsedDate.toLocaleString({ weekday: 'short' }),
            parsedDate.toLocaleString({ month: 'short' }),
            formatEnglishOrdinals(parsedDate.day),
        ];
    }

    return [
        parsedDate.toLocaleString({ weekday: 'short' }),
        parsedDate.toLocaleString({ day: '2-digit' }),
        parsedDate.toLocaleString({ month: 'short' }),
    ];
}

export default function DateBox({
    date,
    backgroundColor,
    large = false,
    textColor,
    width,
    height,
    lineHeight,
    lineThrough = false,
    minHeight,
}: Props) {
    const { t } = useTranslation();

    const dateParts = getDatePartsFromLocale(date);

    const isToday = DateTime.fromISO(date).hasSame(DateTime.now(), 'day');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: width ? width : large ? 100 : 72,
                height: height ? height : large ? 130 : undefined,
                minHeight: minHeight ? minHeight : 0,
                bgcolor: backgroundColor || COLOR.geyser,
                borderRadius: 1,
                alignItems: 'center',
                justifyContent: 'center',
                py: 1,
                borderWidth: large ? 1 : 0,
                borderColor: large ? 'white' : undefined,
            }}
        >
            {isToday ? (
                <Typography
                    className={clsx({
                        'line-through': lineThrough,
                    })}
                    color={textColor}
                >
                    {t('EventsList:today')}
                </Typography>
            ) : (
                <>
                    <Typography
                        data-test-id="DateBox-WeekDay"
                        fontSize={large ? 16 : 12}
                        color={textColor}
                    >
                        {dateParts[0]}
                    </Typography>
                    <Typography
                        data-test-id="DateBox-Day"
                        fontSize={large ? 32 : 20}
                        fontWeight={700}
                        color={textColor}
                        sx={{ lineHeight }}
                    >
                        {dateParts[1]}
                    </Typography>
                    <Typography
                        data-test-id="DateBox-Month"
                        fontSize={large ? 16 : 12}
                        color={textColor}
                    >
                        {dateParts[2]}
                    </Typography>
                </>
            )}
        </Box>
    );
}
