import { Collection, Database, Q } from '@nozbe/watermelondb';

import {
    OrganisationModel,
    OrganisationPayload,
} from '../../types/Organisation';
import { DBServiceOptionsWithImages } from '../../types/dbService';

class Organisation {
    private database: Database;
    private collection: Collection<OrganisationModel>;
    private table = 'organisations';
    private options: DBServiceOptionsWithImages;

    constructor(options: DBServiceOptionsWithImages) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    get() {
        return this.collection.query().fetch();
    }

    getCurrentOrganisationQuery() {
        return this.collection.query();
    }

    getById(id: string) {
        return this.collection.query(Q.where('id', id)).fetch();
    }

    async update(payload: OrganisationPayload, userId: string) {
        const {
            image,
            avatar,
            removeAvatar,
            address,
            alternativeAddress,
            paymentInstructons,
            alternativeEmail,
            alternativePhone,
            alternativePhonePrefix,
            footerMessage,
            taxRate,
            businessNumber,
        } = payload;

        const organiastionElement = await this.get();
        const { id: organisationID } = organiastionElement[0];

        const updatedOrganisation = await this.database.write(async () => {
            const updatedOrganisationElement =
                await organiastionElement[0].update((organisation) => {
                    organisation.name =
                        payload.name || organiastionElement[0].name;
                    organisation.address =
                        address || organiastionElement[0].address;
                    organisation.alternativeAddress =
                        alternativeAddress ||
                        organiastionElement[0].alternativeAddress;
                    organisation.paymentInstructions =
                        paymentInstructons ||
                        organiastionElement[0].paymentInstructions;
                    organisation.alternativeEmail = alternativeEmail ?? '';
                    organisation.alternativePhone = alternativePhone ?? '';
                    organisation.footerMessage = footerMessage ?? '';
                    organisation.taxRate = taxRate ?? '';
                    organisation.businessNumber = businessNumber ?? '';
                    organisation.alternativePhonePrefix =
                        alternativePhonePrefix ?? '';
                });

            this.options.logDBAction({
                message: 'Update organisation',
                modelName: this.table,
                payload: organiastionElement[0],
            });

            return updatedOrganisationElement;
        });

        if (removeAvatar) {
            await this.options.imageService.remove(organisationID);
        }

        if (image && image.uri) {
            this.options.imageService.uploadImage({
                image,
                entityID: organisationID,
                entityType: 'Organisation',
                annotationImage: '',
                ownerID: userId,
                userIDs: [userId],
                documentID: avatar?.documentID,
                organisationID,
            });
        }

        return updatedOrganisation;
    }
}

export default Organisation;
