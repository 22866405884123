import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import equinetLogoImage from 'shared/assets/images/logo/equinet_logo.png';

import bgImage from '@/assets/images/bg-image2.webp';
import { TeamInvitationBadge } from '@/components';
import { APP_CONFIG } from '@/constants/appConfig';
import { useOrganisationIncomingInvitationContext } from '@/context/OrganisationIncomingInvitationContext';
import { AuthPageLayout } from '@/layouts';
import { ROUTE } from '@/router/routes';
import { COLOR } from '@/theme/colors';

import { SignUpForm } from './components';

const CONVERSION_MEASUREMENT_ID = 'AW-701336696/Q2YMCI2C6dkYEPiYts4C';

function SignUpPage() {
    const { t } = useTranslation();
    const { invitationDetails } = useOrganisationIncomingInvitationContext();

    useEffect(() => {
        if (
            window.gtag !== undefined &&
            APP_CONFIG.environmentName === 'production'
        ) {
            window.gtag('event', 'conversion', {
                send_to: CONVERSION_MEASUREMENT_ID,
            });
        }
    }, []);

    const equinetLogo = useMemo(
        () => (
            <Box
                alt="Equinet Logo"
                component="img"
                src={equinetLogoImage}
                sx={{
                    height: 74,
                    width: 280,
                }}
            />
        ),
        [],
    );

    return (
        <AuthPageLayout
            backgroundImage={bgImage}
            navbarContent={
                <Box sx={{ alignItems: 'center', display: 'flex', mr: 4 }}>
                    <Typography
                        sx={{ color: COLOR.white, fontSize: 14, mr: 2 }}
                    >
                        {t('SignUp:text:already_an_account')}
                    </Typography>
                    <Link data-test-id="SignUpPage-LoginLink" to={ROUTE.signIn}>
                        <Button
                            color="white"
                            sx={{ borderColor: COLOR.white, fontWeight: '600' }}
                            variant="outlined"
                        >
                            {t('SignIn:button:sign_in')}
                        </Button>
                    </Link>
                </Box>
            }
            rightColumnContent={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: '8vh',
                        flex: 1,
                    }}
                >
                    <Box sx={{ ml: { xs: 4, md: 10 }, mr: { xs: 4, md: 0 } }}>
                        {invitationDetails ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 3,
                                    alignItems: 'center',
                                }}
                            >
                                {equinetLogo}
                                <TeamInvitationBadge
                                    name={invitationDetails.teamName}
                                    owner={invitationDetails.teamOwner}
                                    imageURL={invitationDetails.teamImageUrl}
                                />
                            </Box>
                        ) : (
                            equinetLogo
                        )}
                        <Typography
                            component="h1"
                            sx={{ fontWeight: 700, mb: 1, mt: 4 }}
                            variant="h5"
                            data-test-id="SignUpPage-SignUp-Header"
                        >
                            {t('SignUp:header:create_equinet_account')}
                        </Typography>
                        <Typography
                            component="p"
                            sx={{ color: 'text.secondary' }}
                            variant="body2"
                        >
                            {t('SignUp:header:app_availability_text')}
                        </Typography>
                    </Box>
                    <SignUpForm />
                </Box>
            }
        />
    );
}

export default SignUpPage;
