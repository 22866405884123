
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components';
import { COLOR } from '@/theme/colors';

import type { Props } from './types';

export const AppointmentCancelledModal = ({ onConfirm }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            modalContentStyles="md:flex-1 md:flex md:justify-center md:items-center mt-10"
            disableRestoreFocus
            styles="w-[341px] px-6 py-5"
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '24px',
                    color: COLOR.ebonyClay,
                }}
            >
                {t('ExternalAppointment:cancelled_modal:title')}
            </Typography>
            <div className="pt-5">
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '300',
                        lineHeight: '26px',
                        color: COLOR.ebonyClay,
                    }}
                >
                    {t('ExternalAppointment:cancelled_modal:cancelled_info')}
                </Typography>
            </div>
            <div className="pt-5">
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '300',
                        lineHeight: '26px',
                        color: COLOR.ebonyClay,
                    }}
                >
                    {t('ExternalAppointment:cancelled_modal:unavailable_info')}
                </Typography>
            </div>
            <div className="self-end md:flex pt-5">
                <Button
                    variant="contained"
                    disableElevation
                    className="py-2"
                    onClick={onConfirm}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '15px',
                            color: COLOR.white,
                        }}
                    >
                        {t('ExternalAppointment:actions:ok').toUpperCase()}
                    </Typography>
                </Button>
            </div>
        </Modal>
    );
};
