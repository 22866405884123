import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Entry from 'shared/db/services/Entry';
import { EntryModel } from 'shared/types/Entries';

import { AddEditEntryModal, BackLinkButton, ErrorState } from '@/components';
import AddEditInvoiceModal from '@/components/AddEditInvoiceModal';
import { useImagesContext } from '@/context/ImagesContext';
import { useDatabase } from '@/hooks';
import { ROUTE } from '@/router/routes';
import Logger from '@/services/logger';

import { EntryDetails } from './components';

function EntryPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getDatabase } = useDatabase();
    const { ImagesService } = useImagesContext();
    const { id } = useParams();

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [entry, setEntry] = useState<EntryModel | null>(null);
    const [isAddInvoiceModalOpen, setIsAddInvoiceModalOpen] =
        useState<boolean>(false);

    const database = useMemo(() => getDatabase(), [getDatabase]);

    const openEditModal = useCallback(() => setIsEditModalOpen(true), []);
    const closeEditModal = useCallback(() => setIsEditModalOpen(false), []);

    const openAddInvoiceModal = useCallback(
        () => setIsAddInvoiceModalOpen(true),
        [],
    );
    const closeAddInvoiceModal = useCallback(
        () => setIsAddInvoiceModalOpen(false),
        [],
    );

    const handleEntryRemoved = useCallback(
        () => navigate(ROUTE.entries),
        [navigate],
    );

    const fetchEntry = useCallback(async () => {
        setIsLoading(true);
        setHasError(false);

        try {
            const entryService = new Entry({
                database,
                imageService: ImagesService,
                logDBAction: Logger.logRecordActivity,
            });

            if (!id) {
                throw new Error();
            }

            const entry = await entryService.getByID(id);

            setEntry(entry);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }, [ImagesService, database, id]);

    useEffect(() => {
        fetchEntry();
    }, [fetchEntry]);

    return (
        <Box sx={{ pb: 10, minWidth: 400 }}>
            {isEditModalOpen && entry ? (
                <AddEditEntryModal
                    close={closeEditModal}
                    onRemove={handleEntryRemoved}
                    entry={entry}
                    isEditMode
                />
            ) : null}
            <Box
                sx={{
                    mb: 3.25,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <BackLinkButton
                    text={t('Entry:button:back')}
                    to={ROUTE.entries}
                    testID="EntryPage-BackButton"
                />
                {!hasError && entry ? (
                    <Button
                        data-test-id="EntryPage-EditButton"
                        sx={{ display: 'flex', gap: 1.5 }}
                        color="inherit"
                        disabled={isLoading}
                        onClick={openEditModal}
                        size="small"
                    >
                        <EditIcon />
                        <Typography fontSize="small" fontWeight={700}>
                            {t('Entry:button:edit')}
                        </Typography>
                    </Button>
                ) : null}
            </Box>
            {hasError ? (
                <ErrorState
                    onRefreshClick={fetchEntry}
                    testID="EntryPage-ErrorState"
                />
            ) : (
                <EntryDetails
                    entry={entry}
                    isLoading={isLoading}
                    onCreateInvoice={openAddInvoiceModal}
                />
            )}
            {isAddInvoiceModalOpen && entry ? (
                <AddEditInvoiceModal
                    close={closeAddInvoiceModal}
                    entry={entry}
                    closeOnSubmit
                />
            ) : null}
        </Box>
    );
}

export default EntryPage;
