import React, {
    createContext,
    createRef,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { INVOICES_FILTER } from 'shared/constants/invoices/filters';
import { InvoiceModel } from 'shared/types/Invoice';
import { InvoicesFiltersObject } from 'shared/types/invoicesFilters';
import { EXPORT_INVOICE_VISIBILITY } from 'shared/types/Invoicing';

import { checkIfAnyFilterIsActive } from '@/helpers/filters';
import { getInitialDateRangeFilterValue } from '@/helpers/invoice/filters';
import { Context } from '@/types/context';

const INITIAL_FILTERS_STATE: InvoicesFiltersObject = {
    [INVOICES_FILTER.status]: [],
    [INVOICES_FILTER.date_range]: getInitialDateRangeFilterValue(),
    [INVOICES_FILTER.show_overdue]: false,
    [INVOICES_FILTER.contacts]: [],
};

const InvoicesFiltersContext = createContext<Context.InvoicesFilters.Value>({
    clearContextState: () => null,
    filtersState: INITIAL_FILTERS_STATE,
    searchState: '',
    setSearchState: () => null,
    exportPanelVisibility: EXPORT_INVOICE_VISIBILITY.HIDDEN,
    selectedRows: [],
    isCheckboxTicked: () => null,
    onCheckboxClick: () => null,
    onAllSelected: () => null,
    isAnyFilterActive: false,
    isFiltersPanelVisible: false,
    resetFilters: () => null,
    setFiltersState: () => null,
    setIsFiltersPanelVisible: () => null,
    setExportPanelVisibility: () => null,
});

const invoicesFiltersContextRef: React.MutableRefObject<Context.InvoicesFilters.Value | null> =
    createRef();

const InvoicesFiltersProvider = ({
    children,
}: Context.InvoicesFilters.Props) => {
    const [filtersState, setFiltersState] = useState<InvoicesFiltersObject>(
        INITIAL_FILTERS_STATE,
    );
    const [searchState, setSearchState] = useState('');
    const [isFiltersPanelVisible, setIsFiltersPanelVisible] = useState(false);
    const [exportPanelVisibility, setExportPanelVisibility] = useState(
        EXPORT_INVOICE_VISIBILITY.HIDDEN,
    );
    const [selectedRows, setSelectedRows] = useState<InvoiceModel[]>([]);

    const isAnyFilterActive = useMemo((): boolean => {
        return checkIfAnyFilterIsActive(filtersState);
    }, [filtersState]);

    const resetFilters = useCallback(() => {
        setFiltersState(INITIAL_FILTERS_STATE);
        setSearchState('');
    }, []);

    const clearContextState = useCallback(() => resetFilters(), [resetFilters]);

    const onCheckboxClick = useCallback(
        (row: InvoiceModel) => {
            const itemExists = !!selectedRows.find(
                (selectedItem) => selectedItem === row,
            );

            if (!itemExists) {
                const updatedRows = [...selectedRows, row];
                setSelectedRows(updatedRows);
            } else {
                const updatedRows = selectedRows.filter(
                    (selectedItem) => selectedItem !== row,
                );
                setSelectedRows(updatedRows);
            }
        },
        [selectedRows],
    );

    const isCheckboxTicked = useCallback(
        (item: InvoiceModel) => !!selectedRows.find((row) => row === item),
        [selectedRows],
    );

    const onAllSelected = useCallback(
        (totalItems: InvoiceModel[]) => {
            const updatedRows: InvoiceModel[] =
                totalItems.length !== selectedRows.length ? totalItems : [];
            setSelectedRows(updatedRows);
        },
        [selectedRows.length],
    );

    const contextValue: Context.InvoicesFilters.Value = {
        clearContextState,
        filtersState,
        searchState,
        setSearchState,
        isAnyFilterActive,
        isFiltersPanelVisible,
        resetFilters,
        setFiltersState,
        setIsFiltersPanelVisible,
        onCheckboxClick,
        isCheckboxTicked,
        onAllSelected,
        setExportPanelVisibility,
        selectedRows,
        exportPanelVisibility,
    };

    invoicesFiltersContextRef.current = contextValue;

    return (
        <InvoicesFiltersContext.Provider value={contextValue}>
            {children}
        </InvoicesFiltersContext.Provider>
    );
};

function useInvoicesFiltersContext() {
    const invoicesFiltersContext = useContext(InvoicesFiltersContext);
    return invoicesFiltersContext;
}

function clearInvoicesFiltersContextState() {
    return invoicesFiltersContextRef.current?.clearContextState();
}

export {
    InvoicesFiltersContext,
    InvoicesFiltersProvider,
    useInvoicesFiltersContext,
    clearInvoicesFiltersContextState,
};
