import { Database } from '@nozbe/watermelondb';
import { t } from 'i18next';
import { INVOICE_STATUS } from 'shared/constants/invoices/statuses';
import { VAT_DESCRIPTION } from 'shared/constants/invoiceVatDescription';
import Entry from 'shared/db/services/Entry';
import { InvoiceModel } from 'shared/types/Invoice';

import { FirebaseImagesService } from '@/services/firebase/images';
import Logger from '@/services/logger';
import { COLOR } from '@/theme/colors';

import { isFutureDate, overdueDaysCalculation } from './date';

export const sumInvoiceEntryProceduresPrice = async (
    db: Database,
    invoices: InvoiceModel[],
): Promise<number> => {
    const entryService = new Entry({
        database: db,
        imageService: new FirebaseImagesService(),
        logDBAction: Logger.logRecordActivity,
    });

    let sum = 0;

    await Promise.all(
        invoices.map(async (invoice) => {
            const entries = await entryService.getByParam(
                'invoice_id',
                invoice.id,
            );
            await Promise.all(
                entries.map(async (entry) => {
                    const entryProcs = await entry.entryProcedures.fetch();
                    entryProcs.forEach((ep) => {
                        const price = Number(ep.price);
                        if (!isNaN(price)) {
                            sum += ep.quantity * price;
                        }
                    });
                }),
            );
        }),
    );

    return sum;
};

export const calculateInvoiceVATLabel = (invoice: InvoiceModel): string => {
    const _vatDescription = {
        [VAT_DESCRIPTION.WITH_VAT]: t('Invoicing:includingGST', {
            defaultValue: 'Including GST',
        }),
        [VAT_DESCRIPTION.WITHOUT_VAT]: t('Invoicing:excludingGST', {
            defaultValue: 'Excluding GST',
        }),
    };

    let vatText;
    let invoiceLabel;

    if (invoice.applyTaxes) {
        invoiceLabel = `${t('Invoicing:total')}`;
        vatText = VAT_DESCRIPTION.WITH_VAT;
    } else {
        invoiceLabel = `${t('Invoicing:subTotal')}`;
        vatText = VAT_DESCRIPTION.WITHOUT_VAT;
    }

    return `${invoiceLabel} ${_vatDescription[vatText]}`;
};

export const calculateInvoicePriceSum = (
    priceSum: number,
    invoice: InvoiceModel,
    currency: string,
): string => {
    let price = '';

    const invoiceTotal = invoice && invoice.total && Number(invoice.total);

    if (typeof invoiceTotal === 'number' && !isNaN(invoiceTotal)) {
        price = `${currency ? currency : '$'} ${invoiceTotal.toFixed(2)}`;
    } else {
        price = `${currency ? currency : '$'} ${priceSum.toFixed(2)}`;
    }
    return price;
};

export const calculateOverdue = (
    overdueDate: string,
    isDateInTheFuture: boolean,
): string => {
    const overdueDays = overdueDaysCalculation(overdueDate);

    if (overdueDays === -1) {
        return '-';
    } else {
        return `${overdueDays.toFixed(0)} ${t('InvoicesPage:days')} ${
            !isDateInTheFuture ? t('InvoicesPage:ago') : ''
        }`;
    }
};

export const getOverdueColor = (dueDateTime: string) => {
    const isDateInTheFuture = isFutureDate(dueDateTime);

    return isDateInTheFuture ? COLOR.warningYellow : COLOR.errorRed;
};

export const getOverdueWithColor = (
    dueDateTime: string,
    status: string,
): { value: string; color: string } => {
    if (status === INVOICE_STATUS.paid) {
        return { value: '-', color: COLOR.white };
    }

    return {
        value: calculateOverdue(dueDateTime, isFutureDate(dueDateTime)),
        color: getOverdueColor(dueDateTime),
    };
};
