import { Collection, Database, Model, Q } from '@nozbe/watermelondb';
import { isEmpty } from 'lodash';

import Entry from './Entry';
import EventContact from './EventContact';
import EventContactReminder from './EventContactReminder';
import EventHorse from './EventHorse';
import Horse from './Horse';
import Location from './Location';
import Organisation from './Organisation';
import {
    DBServiceOptionsWithImages,
    EventDBServiceOptions,
} from '../../types/dbService';
import { EntryModel } from '../../types/Entries';
import { EntryProcedureModel } from '../../types/EntryProcedure';
import { EntryProductModel } from '../../types/EntryProduct';
import {
    EVENT_CONTACT_STATUS,
    EventContactModel,
} from '../../types/EventContact';
import { EventContactReminderModel } from '../../types/EventContactReminder';
import { EventHorseModel } from '../../types/EventHorse';
import { EventModel, EventPayload, EventCategory } from '../../types/Events';
import { HorseModel } from '../../types/Horses';
import { InventoryChangeModel } from '../../types/InventoryChange';
import { InventoryProductModel } from '../../types/InventoryProduct';
import { LocationModel } from '../../types/Location';
import { getLocal, getNowISO, isFuture } from '../../utils/date';
import { getTimeZone } from '../../utils/timezone';
import { EntryUserModel } from '../../types/EntryUser';
import EntryUser from './EntryUser';
import EventUser from './EventUser';
import { EventUserModel } from '../../types/EventUser';
import { EventQueryOptions } from '../../types/eventsFilters';
import { applyEventsSearchQueries } from '../utils';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

class Event {
    private database: Database;
    private collection: Collection<EventModel>;
    private table = 'events';
    private options: EventDBServiceOptions;

    constructor(options: EventDBServiceOptions) {
        this.database = options.database;
        this.collection = options.database.collections.get(this.table);
        this.options = options;
    }

    getAll() {
        return this.collection.query().fetch();
    }

    getByID(id: string) {
        return this.collection.find(id);
    }

    getFutureEventsFilterQuery(options: EventQueryOptions): [Q.Clause[]] {
        const { searchText } = options;

        const parsedSearchText = searchText.toLowerCase().trim();

        const yesterdayDate = getLocal().minus({ days: 1 });

        const queries: Q.Clause[] = [
            Q.where('ends_time', Q.gt(yesterdayDate.toISO())),
            Q.sortBy('starts_time', 'asc'),
        ];

        if (parsedSearchText) {
            queries.push(
                Q.experimentalJoinTables(['event_horses', 'event_contacts']),
                Q.experimentalNestedJoin('event_horses', 'horses'),
                Q.experimentalNestedJoin('event_contacts', 'contacts'),
                ...applyEventsSearchQueries(parsedSearchText),
            );
        }

        return [queries];
    }

    filterFutureEvents(options: EventQueryOptions) {
        const [queries] = this.getFutureEventsFilterQuery(options);

        return this.collection.query(...queries);
    }

    getFilteredFutureEvents(options: EventQueryOptions) {
        const query = this.filterFutureEvents(options);

        return query.fetch();
    }

    getAttentionNeededEvents(organisationId: string) {
        const yesterdayDate = getLocal().minus({ days: 1 });

        const query = this.collection.query(
            Q.where('organisation_id', organisationId),
            Q.where('ends_time', Q.gt(yesterdayDate.toISO())),
            Q.on('event_contacts', Q.where('status', 'rejected')),
            Q.sortBy('starts_time', 'asc'),
        );

        return query.observe();
    }

    async getByParam(param: string, value: any) {
        return this.collection.query(Q.where(param, value)).fetch();
    }

    async getYesterdayEvents() {
        const today = getLocal();
        const yesterday = today.minus({ days: 1 });

        return this.collection.query(
            Q.where(
                'starts_time',
                Q.like(
                    `${Q.sanitizeLikeString(yesterday.toISO().split('T')[0])}%`,
                ),
            ),
        );
    }

    async getFutureReminderEvents() {
        return this.collection
            .query(
                Q.where('user_reminder', Q.notEq(null)),
                Q.where('starts_time', Q.gt(getNowISO())),
            )
            .fetch();
    }

    async getEventsFromDay(day: string) {
        return this.collection
            .query(
                Q.where('starts_time', Q.like(`${Q.sanitizeLikeString(day)}%`)),
            )
            .fetch();
    }

    async getTodayEvents() {
        const now = getNowISO();

        return this.collection
            .query(
                Q.where(
                    'starts_time',
                    Q.like(`${Q.sanitizeLikeString(now.split('T')[0])}%`),
                ),
            )
            .fetch();
    }

    getTodayAndTomorrowEvents(limit: number) {
        const today = getLocal();
        const tomorrow = today.plus({ days: 1 });

        return this.collection.query(
            Q.or(
                Q.where(
                    'starts_time',
                    Q.like(
                        `${Q.sanitizeLikeString(today.toISO().split('T')[0])}%`,
                    ),
                ),
                Q.where(
                    'starts_time',
                    Q.like(
                        `${Q.sanitizeLikeString(
                            tomorrow.toISO().split('T')[0],
                        )}%`,
                    ),
                ),
            ),
            Q.take(limit),
            Q.sortBy('starts_time', Q.asc),
        );
    }

    async add(payload: EventPayload, userId: string) {
        const serviceOptions: DBServiceOptionsWithImages = {
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        };
        const organisationService = new Organisation(serviceOptions);

        const organisation = await organisationService.get();
        const { id: organisationID } = organisation[0];

        const locationService = new Location(serviceOptions);

        let locationElement: LocationModel | undefined;

        if (payload.location) {
            locationElement = await locationService.addUnique(
                payload.location,
                userId,
            );
        }

        let eventContactsToAdd: EventContactModel[] = [];
        let horsesToUpdate: HorseModel[] = [];
        let entryProceduresToAdd: EntryProcedureModel[] = [];
        let entryProductsToAdd: (
            | EntryProductModel
            | InventoryProductModel
            | InventoryChangeModel
        )[] = [];
        let entryUsersToAdd: EntryUserModel[] = [];
        let eventUsersToAdd: EventUserModel[] = [];

        const now = moment().toISOString();
        const newEvent = await this.database.write(async () => {
            const createdEvent = await this.collection.create((event) => {
                event.title = payload.title;
                event.address = payload.address || null;
                event.startsTime = payload.startsTime;
                event.endsTime = payload.endsTime;
                event.notes = payload.notes || null;
                event.userReminder = payload.userReminder;
                event.contactsReminder = payload.contactsReminder;
                event.ownersReminder = payload.ownersReminder;
                event.sendInvitesToContactsAt = now;
                event.locationID = locationElement?.id
                    ? locationElement?.id
                    : null;
                event.userId = userId;
                event.organisationId = organisationID;
                event.timezone = payload.timezone || getTimeZone();
                event.category = payload.category || EventCategory.standard;
                event.isRecurring = payload.isRecurring ?? false;
                event.recurrencePattern = payload.recurrencePattern;
                event.recurrenceEndDate = payload.recurrenceEndDate;
                event.originalEventId = payload.originalEventId;
            });

            this.options.logDBAction({
                message: 'Create event',
                modelName: this.table,
                payload: createdEvent,
            });

            const {
                horses,
                contacts,
                userReminder,
                contactsReminder,
                ownersReminder,
                membersIds,
                invitations,
            } = payload;

            // Add related horses to the event (event_horses)
            if (horses?.length && horses.length > 0) {
                const eventHorsesCollection =
                    this.database.collections.get<EventHorseModel>(
                        'event_horses',
                    );

                for (let horse of horses) {
                    const createdHorse = await eventHorsesCollection.create(
                        (eventHorse) => {
                            eventHorse.eventId = createdEvent.id;
                            eventHorse.userId = createdEvent.userId;
                            eventHorse.organisationId =
                                createdEvent.organisationId;
                            eventHorse.horseId = horse.id;
                        },
                    );

                    this.options.logDBAction({
                        message: 'Create event - create horse',
                        modelName: this.table,
                        payload: createdHorse,
                    });
                }

                // Add Entries & update badge for each horse
                if (payload.createEntries) {
                    const entryService = new Entry(serviceOptions);
                    const entryUserService = new EntryUser(serviceOptions);
                    const horseService = new Horse(serviceOptions);

                    const entryCollection =
                        this.database.collections.get<EntryModel>('entries');

                    for (let horse of horses) {
                        const lastHorseEntry =
                            await entryService.getNewestEntryByHorseID(
                                horse.id,
                            );
                        const horseEntity = await horseService.getByID(
                            horse.id,
                        );
                        let createdEntry: EntryModel;

                        if (lastHorseEntry) {
                            createdEntry = await entryCollection.create(
                                (entry) => {
                                    entry.eventID = createdEvent.id;
                                    entry.userId = createdEvent.userId;
                                    entry.organisationId =
                                        createdEvent.organisationId;
                                    entry.loggedTime = createdEvent.startsTime;
                                    entry.title = lastHorseEntry.title;
                                    entry.notes = lastHorseEntry.notes;
                                    entry.privateNotes =
                                        lastHorseEntry.privateNotes;
                                    entry.horse.set(horseEntity);
                                    entry.event.set(createdEvent);
                                },
                            );

                            this.options.logDBAction({
                                message:
                                    'Create event - create entry (last horse entry)',
                                modelName: this.table,
                                payload: createdEntry,
                            });

                            const entryProducts =
                                await lastHorseEntry.entryProducts.fetch();
                            const entryProductsArr =
                                await entryService.addEntryProducts(
                                    createdEntry,
                                    entryProducts,
                                    userId,
                                );

                            entryProductsToAdd = [
                                ...entryProductsToAdd,
                                ...entryProductsArr,
                            ];

                            const entryProcedures =
                                await lastHorseEntry.entryProcedures.fetch();
                            const entryProceduresArr =
                                await entryService.addEntryProcedures(
                                    createdEntry,
                                    entryProcedures,
                                );

                            entryProceduresToAdd = [
                                ...entryProceduresToAdd,
                                ...entryProceduresArr,
                            ];
                        } else {
                            createdEntry = await entryCollection.create(
                                (entry) => {
                                    entry.eventID = createdEvent.id;
                                    entry.userId = createdEvent.userId;
                                    entry.organisationId =
                                        createdEvent.organisationId;
                                    entry.loggedTime = createdEvent.startsTime;
                                    entry.title = 'service';
                                    entry.horse.set(horseEntity);
                                    entry.event.set(createdEvent);
                                },
                            );

                            this.options.logDBAction({
                                message: 'Create event - create entry',
                                modelName: this.table,
                                payload: createdEntry,
                            });
                        }

                        // entry members are pre-filled with event members
                        if (membersIds) {
                            const entryUsersArr =
                                await entryUserService.prepareAddBatch(
                                    membersIds,
                                    createdEntry.id,
                                );

                            entryUsersToAdd = [
                                ...entryUsersToAdd,
                                ...entryUsersArr,
                            ];
                        }

                        // remove horse badge if the event is in the future
                        if (isFuture(createdEvent.startsTime)) {
                            const horseToUpdate =
                                await horseEntity.prepareUpdate((horse) => {
                                    horse.shoeingCycleBadge = false;
                                });

                            horsesToUpdate = [...horsesToUpdate, horseToUpdate];
                        }
                    }
                }
            }

            // Add related contacts to the event (event_contacts and event_contact_reminders)
            if (contacts?.length && contacts.length > 0) {
                const eventContactsCollection =
                    this.database.collections.get<EventContactModel>(
                        'event_contacts',
                    );
                const eventContactRemindersCollection =
                    this.database.collections.get<EventContactReminderModel>(
                        'event_contact_reminders',
                    );

                await Promise.all(
                    contacts.map(async (contact) => {
                        const eventContact =
                            await eventContactsCollection.prepareCreate(
                                (eventContact) => {
                                    eventContact.eventId = createdEvent.id;
                                    eventContact.userId = createdEvent.userId;
                                    eventContact.organisationId =
                                        createdEvent.organisationId;
                                    eventContact.contactId = contact.id;
                                    eventContact.sentSMS =
                                        invitations?.[contact.id].sms ?? false;
                                    eventContact.sentEmail =
                                        invitations?.[contact.id].email ??
                                        false;
                                    eventContact.status =
                                        EVENT_CONTACT_STATUS.AWAITING;
                                },
                            );

                        eventContactsToAdd.push(eventContact);

                        this.options.logDBAction({
                            message: 'Create event - create event contact',
                            modelName: this.table,
                            payload: eventContact,
                        });

                        if (
                            userReminder &&
                            (contactsReminder || ownersReminder)
                        ) {
                            const createdEventContactReminder =
                                await eventContactRemindersCollection.create(
                                    (eventContactReminder) => {
                                        eventContactReminder.eventId =
                                            createdEvent.id;
                                        eventContactReminder.userId =
                                            createdEvent.userId;
                                        eventContactReminder.organisationId =
                                            createdEvent.organisationId;
                                        eventContactReminder.timeBefore =
                                            userReminder;
                                        eventContactReminder.eventContactId =
                                            eventContact.id;
                                    },
                                );

                            this.options.logDBAction({
                                message:
                                    'Create event - create event contact reminder',
                                modelName: this.table,
                                payload: createdEventContactReminder,
                            });
                        }
                    }),
                );
            }

            if (membersIds?.length) {
                const eventUserService = new EventUser(serviceOptions);
                const eventUsersArr = await eventUserService.prepareAdd(
                    membersIds,
                    createdEvent.id,
                );
                eventUsersToAdd = [...eventUsersToAdd, ...eventUsersArr];
            }

            this.database.batch(
                ...eventContactsToAdd,
                ...horsesToUpdate,
                ...entryProductsToAdd,
                ...entryProceduresToAdd,
                ...entryUsersToAdd,
                ...eventUsersToAdd,
            );

            // Schedule PN for the logged in user, run after all of the database changes regarding the event are made
            if (userReminder) {
                await this.options.notificationsService.scheduleEventLocalNotification(
                    createdEvent._raw,
                );
            }

            this.options.logDBAction({
                message: 'Create event - update horses',
                modelName: this.table,
                payload: horsesToUpdate,
            });
            this.options.logDBAction({
                message: 'Create event - create entry products',
                modelName: this.table,
                payload: entryProductsToAdd,
            });
            this.options.logDBAction({
                message: 'Create event - create entry procedures',
                modelName: this.table,
                payload: entryProceduresToAdd,
            });
            this.options.logDBAction({
                message: 'Create event - create entry users',
                modelName: this.table,
                payload: entryUsersToAdd,
            });
            this.options.logDBAction({
                message: 'Create event - create event users',
                modelName: this.table,
                payload: eventUsersToAdd,
            });

            return createdEvent;
        });

        // Create firebase invitations after event is created
        // to avoid db sync during event creation

        for (let contact of eventContactsToAdd) {
            this.options.firestoreService?.addDocumentToCollection(
                'invitations',
                uuidv4(),
                {
                    organisationId: newEvent.organisationId,
                    eventId: newEvent.id,
                    contactId: contact.contactId,
                    status: EVENT_CONTACT_STATUS.AWAITING,
                },
            );
        }

        return newEvent;
    }

    async removeContact(eventId: string, contactId: string) {
        return this.database.write(async () => {
            const eventContactService = new EventContact({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });
            const eventContactRemindersService = new EventContactReminder({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });

            const [eventContact] = await eventContactService.fetchByParams([
                {
                    name: 'event_id',
                    value: eventId,
                },
                {
                    name: 'contact_id',
                    value: contactId,
                },
            ]);

            if (eventContact) {
                const [eventContactReminder] =
                    await eventContactRemindersService.getByParam(
                        'event_contact_id',
                        eventContact.id,
                    );

                const toDelete: any[] = [eventContact.prepareMarkAsDeleted()];

                if (eventContactReminder) {
                    toDelete.push(eventContactReminder.prepareMarkAsDeleted());
                }

                await this.database.batch(...toDelete);
            }
        });
    }

    async update({
        id,
        notifyContacts,
        payload,
        userId,
    }: {
        id: string;
        notifyContacts?: boolean;
        payload: EventPayload;
        userId: string;
    }) {
        const serviceOptions: DBServiceOptionsWithImages = {
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        };
        const {
            contacts,
            contactsReminder,
            horses,
            location,
            ownersReminder,
            userReminder,
            membersIds,
            invitations,
        } = payload;

        const locationService = new Location(serviceOptions);

        let locationElement: LocationModel | undefined;

        if (location) {
            locationElement = await locationService.addUnique(location, userId);
        }

        const eventElement = await this.getByID(id);

        let eventHorsesToAdd: Model[] = [];
        let eventHorsesToDelete: Model[] = [];

        let eventContactsToAdd: Model[] = [];
        let eventContactsToUpdate: Model[] = [];
        let eventContactsToDelete: Model[] = [];

        let eventContactRemindersToAdd: Model[] = [];
        let eventContactRemindersToUpdate: Model[] = [];
        let eventContactRemindersToDelete: Model[] = [];

        let relatedEntriesToUpdate: Model[] = [];
        let entryProductsToAdd: Model[] = [];
        let entryProceduresToAdd: Model[] = [];

        let horsesToUpdate: Model[] = [];
        let eventUsersToUpdate: EventUserModel[] = [];

        const now = moment().toISOString();
        const updatedEvent = await this.database.write(async () => {
            const updatedEventElement = await eventElement.update((event) => {
                event.title = payload.title;
                event.address = payload.address || null;
                event.startsTime = payload.startsTime;
                event.endsTime = payload.endsTime;
                event.notes = payload.notes || null;
                event.userReminder = payload.userReminder;
                event.contactsReminder = payload.contactsReminder;
                event.ownersReminder = payload.ownersReminder;
                event.sendInvitesToContactsAt = notifyContacts
                    ? now
                    : event.sendInvitesToContactsAt;
                event.locationID =
                    payload.location === undefined
                        ? event.locationID
                        : !isEmpty(locationElement) && locationElement?.id
                        ? locationElement.id
                        : null;
                event.userId = eventElement.userId;
                event.organisationId = eventElement.organisationId;
                event.timezone =
                    payload.timezone || eventElement.timezone || getTimeZone();
                event.category = payload.category || EventCategory.standard;
                event.isRecurring =
                    payload.isRecurring ?? eventElement.isRecurring;
                event.recurrenceEndDate =
                    payload.recurrenceEndDate ?? eventElement.recurrenceEndDate;
                event.recurrencePattern =
                    payload.recurrencePattern ?? eventElement.recurrencePattern;
                event.originalEventId =
                    payload.originalEventId ?? eventElement.originalEventId;
            });

            this.options.logDBAction({
                message: 'Update event',
                modelName: this.table,
                payload: eventElement,
            });

            // Update Scheduled PN for the logged in user
            const cacheKey = `notification_${id}`;
            const cachedNotification =
                await this.options.cacheService.get<string>(cacheKey);

            if (cachedNotification) {
                await this.options.cacheService.clear(cacheKey);

                this.options.notificationsService.cancelNotification({
                    id: cachedNotification,
                });
            }

            // Update related horses to the event (event_horses)
            const eventHorseService = new EventHorse(serviceOptions);
            const horseService = new Horse(serviceOptions);
            const entryService = new Entry(serviceOptions);

            const initialHorses = await eventElement.horses.fetch();

            const eventHorsesCollection =
                this.database.collections.get<EventHorseModel>('event_horses');

            const eventHorses = await eventHorseService.getByParam(
                'event_id',
                eventElement.id,
            );

            if (horses) {
                for (let horse of horses) {
                    // horse is new so create needed data related to it
                    if (
                        !initialHorses.find(
                            (initialHorse) => initialHorse.id === horse.id,
                        )
                    ) {
                        const eventHorseToAdd =
                            await eventHorsesCollection.prepareCreate(
                                (eventHorse) => {
                                    eventHorse.eventId = eventElement.id;
                                    eventHorse.horseId = horse.id;
                                    eventHorse.userId = eventElement.userId;
                                    eventHorse.organisationId =
                                        eventElement.organisationId;
                                },
                            );

                        eventHorsesToAdd = [
                            ...eventHorsesToAdd,
                            eventHorseToAdd,
                        ];

                        const horseEntity = await horseService.getByID(
                            horse.id,
                        );

                        // remove horse badge if the event is in the future
                        if (isFuture(eventElement.startsTime)) {
                            const horseToUpdate =
                                await horseEntity.prepareUpdate((horse) => {
                                    horse.shoeingCycleBadge = false;
                                });

                            horsesToUpdate = [...horsesToUpdate, horseToUpdate];
                        }
                    }
                }
            }

            if (horses) {
                await Promise.all(
                    eventHorses.map(async (eventHorse) => {
                        // horse was removed
                        if (
                            !horses.find(
                                (horse) => horse.id === eventHorse.horseId,
                            )
                        ) {
                            eventHorsesToDelete = [
                                ...eventHorsesToDelete,
                                eventHorse.prepareMarkAsDeleted(),
                            ];

                            // Unlink the Horse Entry from the Event
                            const horseEntry = await entryService.getByParams({
                                event_id: id,
                                horse_id: eventHorse.horseId,
                            });

                            if (horseEntry[0]) {
                                const entryToUpdate =
                                    await horseEntry[0].prepareUpdate(
                                        (relatedEntry) => {
                                            relatedEntry.eventID = '';
                                            relatedEntry.loggedTime =
                                                eventElement.startsTime;
                                        },
                                    );

                                relatedEntriesToUpdate = [
                                    ...relatedEntriesToUpdate,
                                    entryToUpdate,
                                ];
                            }
                        }
                    }),
                );
            }

            // Update related contacts to the event (event_contacts and event_contact_reminders)
            const initialContacts = await eventElement.contacts.fetch();
            const eventContactsCollection =
                this.database.collections.get<EventContactModel>(
                    'event_contacts',
                );
            const eventContactService = new EventContact({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });
            const eventContactRemindersCollection =
                this.database.collections.get<EventContactReminderModel>(
                    'event_contact_reminders',
                );
            const eventContactRemindersService = new EventContactReminder({
                database: this.database,
                imageService: this.options.imageService,
                logDBAction: this.options.logDBAction,
            });
            // need EventContact instance to delete EventContact record type
            const eventContacts = await eventContactService.fetchByParam(
                'event_id',
                eventElement.id,
            );

            if (contacts) {
                await Promise.all(
                    contacts.map(async (contact) => {
                        const initialContact = initialContacts.find(
                            (initialContact) =>
                                initialContact.id === contact.id,
                        );

                        if (!initialContact) {
                            const eventContactToAdd =
                                await eventContactsCollection.prepareCreate(
                                    (eventContact) => {
                                        eventContact.eventId = eventElement.id;
                                        eventContact.contactId = contact.id;
                                        eventContact.userId =
                                            eventElement.userId;
                                        eventContact.organisationId =
                                            eventElement.organisationId;
                                        eventContact.sentSMS =
                                            invitations?.[contact.id].sms ??
                                            false;
                                        eventContact.sentEmail =
                                            invitations?.[contact.id].email ??
                                            false;
                                        eventContact.status =
                                            EVENT_CONTACT_STATUS.AWAITING;
                                    },
                                );

                            this.options.firestoreService?.addDocumentToCollection(
                                'invitations',
                                uuidv4(),
                                {
                                    organisationId: eventElement.organisationId,
                                    eventId: eventElement.id,
                                    contactId: contact.id,
                                    status: EVENT_CONTACT_STATUS.AWAITING,
                                },
                            );

                            if (
                                userReminder &&
                                (contactsReminder || ownersReminder)
                            ) {
                                const eventContactReminderToAdd =
                                    await eventContactRemindersCollection.prepareCreate(
                                        (eventContactReminder) => {
                                            eventContactReminder.eventId = id;
                                            eventContactReminder.timeBefore =
                                                userReminder;
                                            eventContactReminder.eventContactId =
                                                eventContactToAdd.id;
                                            eventContactReminder.userId =
                                                eventElement.userId;
                                            eventContactReminder.organisationId =
                                                eventElement.organisationId;
                                        },
                                    );
                                eventContactRemindersToAdd = [
                                    ...eventContactRemindersToAdd,
                                    eventContactReminderToAdd,
                                ];
                            }

                            eventContactsToAdd = [
                                ...eventContactsToAdd,
                                eventContactToAdd,
                            ];
                        } else {
                            const eventContact = eventContacts.find(
                                (eventContact) =>
                                    eventContact.contactId ===
                                    initialContact.id,
                            );
                            const eventContactReminder =
                                await eventContactRemindersService.getByParam(
                                    'event_contact_id',
                                    eventContact?.id,
                                );
                            if (
                                userReminder &&
                                (contactsReminder || ownersReminder) &&
                                eventContactReminder.length === 0
                            ) {
                                const eventContactReminderToAdd =
                                    await eventContactRemindersCollection.prepareCreate(
                                        (
                                            eventContactReminder: EventContactReminderModel,
                                        ) => {
                                            eventContactReminder.eventId = id;
                                            eventContactReminder.timeBefore =
                                                userReminder;
                                            eventContactReminder.eventContactId =
                                                eventContact?.id;
                                            eventContactReminder.userId =
                                                eventElement.userId;
                                            eventContactReminder.organisationId =
                                                eventElement.organisationId;
                                        },
                                    );
                                eventContactRemindersToAdd = [
                                    ...eventContactRemindersToAdd,
                                    eventContactReminderToAdd,
                                ];
                            }
                        }
                    }),
                );
            }

            if (contacts) {
                await Promise.all(
                    eventContacts.map(async (eventContact) => {
                        const eventContactReminder =
                            await eventContactRemindersService.getByParam(
                                'event_contact_id',
                                eventContact.id,
                            );

                        if (
                            !contacts.find(
                                (contact) =>
                                    contact.id === eventContact.contactId,
                            )
                        ) {
                            eventContactsToDelete = [
                                ...eventContactsToDelete,
                                eventContact.prepareMarkAsDeleted(),
                            ];

                            if (eventContactReminder.length !== 0) {
                                eventContactRemindersToDelete = [
                                    ...eventContactRemindersToDelete,
                                    eventContactReminder[0].prepareMarkAsDeleted(),
                                ];
                            }
                        } else {
                            if (invitations) {
                                const contactInvitation =
                                    invitations?.[eventContact.contactId];
                                if (
                                    contactInvitation &&
                                    (eventContact.sentSMS !==
                                        contactInvitation?.sms ||
                                        eventContact.sentEmail !==
                                            contactInvitation?.email)
                                ) {
                                    const eventContactToUpdate =
                                        eventContact.prepareUpdate((ec) => {
                                            ec.sentSMS =
                                                contactInvitation?.sms ??
                                                ec.sentSMS;
                                            ec.sentEmail =
                                                contactInvitation?.email ??
                                                ec.sentEmail;
                                        });

                                    eventContactsToUpdate = [
                                        ...eventContactsToUpdate,
                                        eventContactToUpdate,
                                    ];
                                }
                            }

                            if (eventContactReminder.length !== 0) {
                                if (contactsReminder || ownersReminder) {
                                    if (
                                        userReminder &&
                                        eventContactReminder[0].timeBefore !==
                                            userReminder
                                    ) {
                                        const eventContactReminderToUpdate =
                                            await eventContactReminder[0].prepareUpdate(
                                                (eventContactReminder) => {
                                                    eventContactReminder.timeBefore =
                                                        userReminder;
                                                },
                                            );
                                        eventContactRemindersToUpdate = [
                                            ...eventContactRemindersToUpdate,
                                            eventContactReminderToUpdate,
                                        ];
                                    }
                                } else {
                                    eventContactRemindersToDelete = [
                                        ...eventContactRemindersToDelete,
                                        eventContactReminder[0].prepareMarkAsDeleted(),
                                    ];
                                }
                            }
                        }
                    }),
                );
            }

            // Update entries date
            const relatedEntries = await entryService.getByParam(
                'event_id',
                id,
            );
            const inventoryChangesToAdd: Model[] = [];

            if (relatedEntries.length > 0) {
                await Promise.all(
                    relatedEntries.map(async (entry) => {
                        if (
                            !relatedEntriesToUpdate.some(
                                (relatedEntry) => relatedEntry.id === entry.id,
                            )
                        ) {
                            const initialLoggedTime = entry.loggedTime;
                            const entryToUpdate = await entry.prepareUpdate(
                                (relatedEntry) => {
                                    relatedEntry.loggedTime =
                                        eventElement.startsTime;
                                },
                            );

                            relatedEntriesToUpdate.push(entryToUpdate);

                            const entryProducts =
                                await entry.entryProducts.fetch();

                            await Promise.all(
                                entryProducts.map(async (prod) => {
                                    const revert =
                                        await entryService.prepareRevertInventoryChangeForEntryProduct(
                                            prod,
                                            initialLoggedTime,
                                            eventElement.startsTime,
                                            userId,
                                        );

                                    inventoryChangesToAdd.push(...revert);
                                }),
                            );
                        }
                    }),
                );
            }

            if (membersIds !== undefined) {
                const eventUserService = new EventUser(serviceOptions);
                const eventUsersBatch =
                    await eventUserService.prepareUpdateBatch(membersIds, id);

                eventUsersToUpdate = [
                    ...eventUsersToUpdate,
                    ...eventUsersBatch.usersToAdd,
                    ...eventUsersBatch.usersToDelete,
                ];
            }

            this.database.batch(
                ...eventHorsesToAdd,
                ...eventHorsesToDelete,
                ...eventContactsToAdd,
                ...eventContactsToUpdate,
                ...eventContactsToDelete,
                ...eventContactRemindersToAdd,
                ...eventContactRemindersToUpdate,
                ...eventContactRemindersToDelete,
                ...relatedEntriesToUpdate,
                ...horsesToUpdate,
                ...entryProductsToAdd,
                ...entryProceduresToAdd,
                ...inventoryChangesToAdd,
                ...eventUsersToUpdate,
            );

            if (userReminder) {
                await this.options.notificationsService.scheduleEventLocalNotification(
                    eventElement._raw,
                );
            }

            this.logUpdateBatch({
                eventHorsesToAdd,
                eventHorsesToDelete,
                eventContactsToAdd,
                eventContactsToUpdate,
                eventContactsToDelete,
                eventContactRemindersToAdd,
                eventContactRemindersToUpdate,
                eventContactRemindersToDelete,
                relatedEntriesToUpdate,
                horsesToUpdate,
                entryProductsToAdd,
                entryProceduresToAdd,
                inventoryChangesToAdd,
                eventUsersToUpdate,
            });

            return updatedEventElement;
        });

        return updatedEvent;
    }

    private logUpdateBatch({
        eventHorsesToAdd,
        eventHorsesToDelete,
        eventContactsToAdd,
        eventContactsToUpdate,
        eventContactsToDelete,
        eventContactRemindersToAdd,
        eventContactRemindersToUpdate,
        eventContactRemindersToDelete,
        relatedEntriesToUpdate,
        horsesToUpdate,
        entryProductsToAdd,
        entryProceduresToAdd,
        inventoryChangesToAdd,
        eventUsersToUpdate,
    }) {
        if (eventHorsesToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update event - create event horses',
                modelName: this.table,
                payload: eventHorsesToAdd,
            });
        }

        if (eventHorsesToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Update event - delete event horses',
                modelName: this.table,
                payload: eventHorsesToDelete,
            });
        }

        if (eventContactsToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update event - create event contacts',
                modelName: this.table,
                payload: eventContactsToAdd,
            });
        }

        if (eventContactsToUpdate.length > 0) {
            this.options.logDBAction({
                message: 'Update event - update event contacts',
                modelName: this.table,
                payload: eventContactsToUpdate,
            });
        }

        if (eventContactsToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Update event - delete event contacts',
                modelName: this.table,
                payload: eventContactsToDelete,
            });
        }

        if (eventContactRemindersToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update event - create event contact reminders',
                modelName: this.table,
                payload: eventContactRemindersToAdd,
            });
        }

        if (eventContactRemindersToUpdate.length > 0) {
            this.options.logDBAction({
                message: 'Update event - update event contact reminders',
                modelName: this.table,
                payload: eventContactRemindersToUpdate,
            });
        }

        if (eventContactRemindersToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Update event - delete event contact reminders',
                modelName: this.table,
                payload: eventContactRemindersToDelete,
            });
        }

        if (relatedEntriesToUpdate.length > 0) {
            this.options.logDBAction({
                message: 'Update event - update related entries',
                modelName: this.table,
                payload: relatedEntriesToUpdate,
            });
        }

        if (horsesToUpdate.length > 0) {
            this.options.logDBAction({
                message: 'Update event - update horses',
                modelName: this.table,
                payload: horsesToUpdate,
            });
        }

        if (entryProductsToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update event - create entry products',
                modelName: this.table,
                payload: entryProductsToAdd,
            });
        }

        if (entryProceduresToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update event - create entry procedures',
                modelName: this.table,
                payload: entryProceduresToAdd,
            });
        }

        if (inventoryChangesToAdd.length > 0) {
            this.options.logDBAction({
                message: 'Update event - create inventory changes',
                modelName: this.table,
                payload: inventoryChangesToAdd,
            });
        }

        if (eventUsersToUpdate?.length) {
            this.options.logDBAction({
                message: 'Update event - update event users',
                modelName: this.table,
                payload: eventUsersToUpdate,
            });
        }
    }

    async deleteByID(id: string, userId: string) {
        const serviceOptions: DBServiceOptionsWithImages = {
            database: this.database,
            imageService: this.options.imageService,
            logDBAction: this.options.logDBAction,
        };

        const eventElement = await this.getByID(id);
        const eventHorses = await eventElement.horses.fetch();
        const eventContacts = await eventElement.contacts.fetch();

        let eventHorsesToDelete: EventHorseModel[] = [];
        let eventContactsToDelete: EventContactModel[] = [];
        let eventContactRemindersToDelete: EventContactReminderModel[] = [];
        let relatedEntriesToUpdate: EntryModel[] = [];
        let relatedEntriesToDelete: (
            | EntryProductModel
            | EntryProcedureModel
            | EntryModel
        )[] = [];

        await this.database.write(async () => {
            await eventElement.markAsDeleted();

            this.options.logDBAction({
                message: 'Delete event',
                modelName: this.table,
                payload: eventElement,
            });

            // Delete related horses to the event (event_horses)
            if (eventHorses.length > 0) {
                const eventHorseService = new EventHorse(serviceOptions);

                const eventHorses = await eventHorseService.getByParam(
                    'event_id',
                    id,
                );

                await Promise.all(
                    eventHorses.map((eventHorse) => {
                        eventHorsesToDelete = [
                            ...eventHorsesToDelete,
                            eventHorse.prepareMarkAsDeleted(),
                        ];
                    }),
                );
            }

            // Delete related contacts to the event (event_contacts)
            if (eventContacts.length > 0) {
                const eventContactService = new EventContact(serviceOptions);

                const eventContacts = await eventContactService.fetchByParam(
                    'event_id',
                    id,
                );

                await Promise.all(
                    eventContacts.map((eventContact) => {
                        eventContactsToDelete = [
                            ...eventContactsToDelete,
                            eventContact.prepareMarkAsDeleted(),
                        ];
                    }),
                );
            }

            // Cancel scheduled event Push Notification
            const cacheKey = `notification_${id}`;
            const cachedNotification =
                await this.options.cacheService.get<string>(cacheKey);

            if (cachedNotification) {
                await this.options.cacheService.clear(cacheKey);

                this.options.notificationsService.cancelNotification({
                    id: cachedNotification,
                });
            }

            // Delete related event contact reminders
            const eventContactRemindersService = new EventContactReminder(
                serviceOptions,
            );

            const eventContactReminders =
                await eventContactRemindersService.getByParam('event_id', id);
            if (eventContactReminders.length > 0) {
                await Promise.all(
                    eventContactReminders.map((eventContactReminder) => {
                        eventContactRemindersToDelete = [
                            ...eventContactRemindersToDelete,
                            eventContactReminder.prepareMarkAsDeleted(),
                        ];
                    }),
                );
            }

            // Delete Entries if Event is in future. Else, update Entries
            const entryService = new Entry(serviceOptions);

            const relatedEntries = await entryService.getByParam(
                'event_id',
                id,
            );

            if (relatedEntries.length > 0) {
                if (isFuture(eventElement.startsTime)) {
                    const deletePromises = relatedEntries.map(async (entry) =>
                        entryService.prepareDeleteById(entry.id, userId),
                    );

                    relatedEntriesToDelete = (
                        await Promise.all(deletePromises)
                    ).flatMap((c) => c);
                } else {
                    await Promise.all(
                        relatedEntries.map(async (entry) => {
                            const entryToUpdate = await entry.prepareUpdate(
                                (relatedEntry) => {
                                    relatedEntry.eventID = '';
                                    relatedEntry.loggedTime =
                                        eventElement.startsTime;
                                },
                            );

                            relatedEntriesToUpdate = [
                                ...relatedEntriesToUpdate,
                                entryToUpdate,
                            ];
                        }),
                    );
                }
            }

            this.database.batch(
                ...eventHorsesToDelete,
                ...eventContactsToDelete,
                ...eventContactRemindersToDelete,
                ...relatedEntriesToDelete,
                ...relatedEntriesToUpdate,
            );
            this.logDeleteBatch({
                eventHorsesToDelete,
                eventContactsToDelete,
                eventContactRemindersToDelete,
                relatedEntriesToDelete,
                relatedEntriesToUpdate,
            });
        });

        const eventUsersService = new EventUser(serviceOptions);
        await eventUsersService.deleteAllUsersByEventId(id);
    }

    private logDeleteBatch({
        eventHorsesToDelete,
        eventContactsToDelete,
        eventContactRemindersToDelete,
        relatedEntriesToDelete,
        relatedEntriesToUpdate,
    }) {
        if (eventHorsesToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Delete event - delete event horses',
                modelName: this.table,
                payload: eventHorsesToDelete,
            });
        }

        if (eventContactsToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Delete event - delete event contacts',
                modelName: this.table,
                payload: eventContactsToDelete,
            });
        }

        if (eventContactRemindersToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Delete event - delete event contact reminders',
                modelName: this.table,
                payload: eventContactRemindersToDelete,
            });
        }

        if (relatedEntriesToDelete.length > 0) {
            this.options.logDBAction({
                message: 'Delete event - delete related entries',
                modelName: this.table,
                payload: relatedEntriesToDelete,
            });
        }

        if (relatedEntriesToUpdate.length > 0) {
            this.options.logDBAction({
                message: 'Delete event - update related entries',
                modelName: this.table,
                payload: relatedEntriesToUpdate,
            });
        }
    }
}

export default Event;
