import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { LOCALE } from 'shared/constants/localization/types';
import { Region } from 'shared/types/regions';

import { RegionsAPI } from '@/services/networking/regions';
import { Snackbar } from '@/services/toastNotifications';
import { t } from '@/services/translations/config';
import { Context } from '@/types/context';

const LocalizationContext = createContext<Context.Localization.Value>({
    regions: [],
    languages: [],
    isLoading: false,
});

const LocalizationContextProvider = ({
    children,
}: Context.Localization.Props) => {
    const [regions, setRegions] = useState<Region[]>([]);
    const [languages, setLanguages] = useState<LOCALE[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchRegions = useCallback(async () => {
        try {
            setIsLoading(true);
            const fetchedRegions = await RegionsAPI.getRegions();
            setRegions(fetchedRegions.payload);

            const languages = [
                ...new Set(
                    fetchedRegions.payload.map(
                        (region) => region.proposed_language,
                    ),
                ),
            ];
            setLanguages(languages);
        } catch {
            Snackbar.showToastNotification({
                message: t('Regions:error'),
                options: { variant: 'error' },
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    const contextValue: Context.Localization.Value = {
        regions,
        languages,
        isLoading,
    };

    return (
        <LocalizationContext.Provider value={contextValue}>
            {children}
        </LocalizationContext.Provider>
    );
};

function useLocalizationContext() {
    const context = useContext(LocalizationContext);

    return context;
}

export {
    LocalizationContext,
    LocalizationContextProvider,
    useLocalizationContext,
};
